"use strict";

import modules from "ym";

/**
 * @module loader_type_js
 * @description Load JS from external URL.
 */
modules.define("loader_type_js", function (provide) {
  var loading = {};
  var loaded = {};

  var runCallbacks = function (path, type) {
    var cbs = loading[path];
    var cb;
    var i = 0;
    delete loading[path];
    while ((cb = cbs[i++])) {
      cb[type] && cb[type]();
    }
  };

  var onSuccess = function (path) {
    loaded[path] = true;
    runCallbacks(path, "success");
  };

  var onError = function (path) {
    runCallbacks(path, "error");
  };

  provide(
    /**
     * @exports
     * @param {String} path resource link
     * @param {Function} success to be called if the script succeeds
     * @param {Function} error to be called if the script fails
     */
    function (path, success, error) {
      if (loaded[path]) {
        success();
        return;
      }

      if (loading[path]) {
        loading[path].push({
          success: success,
          error: error,
        });
        return;
      }

      loading[path] = [
        {
          success: success,
          error: error,
        },
      ];

      setTimeout(function () {
        var append = document.getElementsByTagName("script")[0];
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.charset = "utf-8";
        script.src =
          (location.protocol === "file:" && !path.indexOf("//")
            ? "http:"
            : "") + path;

        if ("onload" in script) {
          script.onload = function () {
            script.onload = script.onerror = null;
            onSuccess(path);
          };
          script.onerror = function () {
            script.onload = script.onerror = null;
            onError(path);
          };
        } else {
          script.onreadystatechange = function () {
            var readyState = this.readyState;
            if (readyState === "loaded" || readyState === "complete") {
              script.onreadystatechange = null;
              onSuccess(path);
            }
          };
        }

        append.parentNode.insertBefore(script, append);
      }, 0);
    }
  );
});
