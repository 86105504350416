import $ from "jquery";
import modules from "ym";

modules.define("datepicker", (provide) => {
  require.ensure(
    [],
    (require) => {
      require("jquery-ui/ui/widgets/datepicker.js");
      require("jquery-ui/ui/i18n/datepicker-ru.js");
      provide($.fn.datepicker);
    },
    "jquery-ui-datepicker"
  );
});
