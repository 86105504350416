"use strict";

import modules from "ym";

modules.define("googletag", ["onload", "loader_type_all"], function (
  provide,
  onload,
  loader
) {
  "use strict";

  window.googletag = window.googletag || {};
  window.googletag.cmd = window.googletag.cmd || [];

  onload(function () {
    loader({
      js: "//www.googletagservices.com/tag/js/gpt.js",
      success: function () {
        provide(window.googletag);
      },
    });
  });
});
