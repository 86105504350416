import $ from "jquery";

$(document).on("click", "[data-google-translate]", function (e) {
  var cleaner = document.createElement("div");
  cleaner.innerHTML = $("#" + $(this).attr("data-google-translate")).val();
  console.log($("#" + $(this).attr("data-google-translate")));
  $(this).attr(
    "href",
    "https://translate.google.com.ua/#" +
      $(this).data("from") +
      "/" +
      $(this).data("to") +
      "/" +
      encodeURIComponent(cleaner.innerText)
  );
});
