// ----------------------------------------------------------------------------
// спойлер

import "./est-details.scss";

import $ from "jquery";

$(document).on("click", ".est-details__toggler", function () {
  $(this).closest(".est-details").toggleClass("est-details--closed");
});
