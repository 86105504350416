import modules from "ym";

const versions = require("./versions.json");
const scheme = "https:" === window.location.protocol ? "https" : "http";
const basepath =
  scheme + "://cdnjs.cloudflare.com/ajax/libs/jcarousel/" + versions.jcarousel;

modules.define("jcarousel", ["loader_type_all", "jquery"], function (
  provide,
  loader,
  $
) {
  loader({
    js: basepath + "/jquery.jcarousel.min.js",
    success: function () {
      if ("undefined" !== typeof $.fn.jcarousel) {
        provide($.fn.jcarousel);
      }
    },
  });
});
