// ----------------------------------------------------------------------------
// виджет поиска в списке

import $ from "jquery";

var simplifyString = function (string) {
  return string
    .toLowerCase()
    .replace(/[^a-zA-Zа-яіїєёґА-ЯЁІЇЄҐ0-9]+/g, " ")
    .replace(/\s+/g, " ")
    .replace(/^\s+|\s+$/g, "");
};

$(document).on(
  "est-filter change input keypress",
  ".est-filter__input",
  function () {
    var list = $(this).closest(".est-filter");
    var items = list.find(".est-filter__item");
    var search = simplifyString($(this).val());

    items.each(function () {
      var item = $(this);
      var text = simplifyString(item.attr("data-filter-text"));
      var pattern = new RegExp(
        "^" + search + "|" + ("\b" + search) + "|" + (" " + search)
      );

      !search || text.match(pattern)
        ? item.removeAttr("hidden")
        : item.attr("hidden", "hidden");
    });
  }
);

$(document).on("dropdown-activated", function (e) {
  $(e.target)
    .next(".est-dropdown")
    .find(".est-filter__input")
    .val([])
    .trigger("est-filter");
});
