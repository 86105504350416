"use strict";

import closest from "./closest.js";

const on = function on(event, element, selector, callback) {
  if (typeof element === "string") {
    Array.prototype.forEach.call(document.querySelectorAll(element), function (
      element
    ) {
      on(event, element, selector, callback);
    });
  } else if (typeof selector === "string" && typeof callback === "function") {
    element.addEventListener(event, function (e) {
      const source = e.target || e.srcElement;
      const target = closest(source, selector, element);
      if (target) {
        callback.call(target, e);
      }
    });
  } else if (typeof selector === "function") {
    element.addEventListener(event, selector);
  }
};

export default on;
