import modules from "ym";

import "./jquery";
import "./loader_type_all";

const versions = require("./versions.json");
const basepath = `https://cdnjs.cloudflare.com/ajax/libs/jquery.fancytree/${versions.fancytree}`;

modules.define(
  "fancytree",
  ["jquery", "loader_type_all"],
  (provide, $, loader) => {
    const scheme = "https:" === window.location.protocol ? "https" : "http";

    loader({
      css: basepath + "/skin-xp/ui.fancytree.min.css",
      js: [
        basepath + "/jquery.fancytree-all.min.js",
        // зависимость от jquery-ui-contextmenu
        `https://cdnjs.cloudflare.com/ajax/libs/jquery.ui-contextmenu/${versions.jquery_ui_contextmenu}/jquery.ui-contextmenu.min.js`,
      ],
      success() {
        if (typeof $.fn.fancytree != "undefined") {
          provide($.fn.fancytree);
        }
      },
    });
  }
);
