import "./auth.scss";

import $ from "jquery";
import modules from "ym";
import hash from "../../lib/hash";
import { language } from "../../lib/language";

function getParameterByName(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  const results = regex.exec(location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

const lang_prefix = "/" + language;

// Открытие диалога авторизации при клике по соответствующей ссылке
$(document).on("click", ".link-login", function () {
  const link = $(this);
  const href = link.attr("href") || location.href;
  const title = link.attr("data-login-title") || "";
  const width = link.attr("data-login-width") || 320 + 30 * 2;
  const data = {
    sf_format: "jsonp",
    title: title,
    ajax: true,
  };

  $.ajax({
    url: "//" + sf_host + lang_prefix + "/login/",
    data: data,
    cache: false,
    overlay: true,
    dataType: "jsonp",
    success: function (data) {
      const dialog = $($.parseHTML((data || "").trim()));
      const form = dialog.find("form");

      if (!href.match(/\/login\//)) {
        form.data("link-login", link);
      }

      dialog.appendTo(document.body);
      dialog.estdialog({
        destroyOnClose: true,
        autoOpen: true,
        width: width,
        modal: true,
        open: function () {
          setTimeout(() => $("#auth_username").focus(), 1500);
        },
      });
    },
  });

  return false;
});

// Отправка формы авторизации
$(document).on("submit", ".form-signin", function (e) {
  e.preventDefault();
  const form = $(".form-signin");
  const link = form.data("link-login");
  const data = Object.assign({}, link ? link.data("login-data") || {} : {}, {
    ajax: true,
  });

  modules.require(["storage"], () => {});

  form.ajaxSubmit({
    xhrFields: {
      withCredentials: true,
    },
    data: data,
    crossDomain: true,
    dataType: "json",
    method: "post",
    cache: false,
    success: (data) => {
      if (data.error) {
        $(".error_block").html(`<p>${data.error}</p>`);
      } else {
        $(".error_block").empty();
      }
      if (data.redirect) {
        modules.require(["storage"], function (storage) {
          storage.local.removeItem("estmarks");
        });
        form.is(".ui-dialog-content form") &&
          form.closest(".ui-dialog-content").estdialog("close");
        if (link) {
          top.location.assign(link.attr("href") || data.redirect);
        } else {
          top.location.assign(data.redirect);
        }
      }
    },
  });
});

// Открытие диалога регистрации при активации соответствующего url хэша
(() => {
  const hash_change_handler = function () {
    if (hash.hasItem("registration")) {
      hash.removeItem("registration");

      $(function () {
        $(document).trigger("command-close-dialogs");

        const socialToken =
          getParameterByName("social-token") !== null
            ? getParameterByName("social-token")
            : $(".registration-choose-back").data("social-token");

        $.ajax({
          url: "//" + sf_host + lang_prefix + "/registration/",
          data: {
            "ajax": true,
            "sf_format": "json",
            "social-token": socialToken,
            "from-oauth": getParameterByName("from-oauth"),
          },
          dataType: "json",
          cache: false,
          overlay: true,
          success: function (data) {
            var dlg = $($.parseHTML($.trim(data)));
            dlg.appendTo(document.body);
            dlg.estdialog({
              destroyOnClose: true,
              autoOpen: true,
              width: 590,
              modal: true,
            });
          },
        });
      });
    }
    if (hash.hasItem("oauth-registration")) {
      const email = getParameterByName("email");
      const first_name = getParameterByName("first_name");

      hash.removeItem("oauth-registration");

      $(function () {
        $(document).trigger("command-close-dialogs");

        $.ajax({
          url: "//" + sf_host + lang_prefix + "/registration/person/",
          data: {
            sf_format: "jsonp",
            email: email,
            first_name: first_name,
            oauth: true,
          },
          dataType: "jsonp",
          cache: false,
          overlay: true,
          success: (data) => {
            const dlg = $($.parseHTML($.trim(data)));

            dlg.appendTo(document.body);
            dlg.estdialog({
              destroyOnClose: true,
              autoOpen: true,
              width: 360,
              modal: true,
            });
          },
        });
      });
    }
    if (hash.hasItem("collectdata")) {
      hash.removeItem("collectdata");

      $(() => {
        $(document).trigger("command-close-dialogs");

        const socialToken =
          getParameterByName("social-token") !== null
            ? getParameterByName("social-token")
            : $(".registration-choose-back").data("social-token");

        $.ajax({
          url: "//" + sf_host + lang_prefix + "/registration/choose-type/",
          data: {
            "ajax": true,
            "sf_format": "json",
            "social-token": socialToken,
            "from-oauth": getParameterByName("from-oauth"),
          },
          dataType: "json",
          cache: false,
          overlay: true,
          success: function (data) {
            const dlg = $($.parseHTML($.trim(data)));

            dlg.appendTo(document.body);
            dlg.estdialog({
              destroyOnClose: true,
              autoOpen: true,
              width: 590,
              modal: true,
            });
          },
        });
      });
    }
  };

  $(window).on("hashchange", hash_change_handler);

  hash_change_handler();
})();

// Инициализация диалога авторизации
$(document).on("estdialogcreate", ".dialog-signin", function () {});

// Инициализация диалога регистрации частного лица и валидация
$(document).on("estdialogcreate", ".registration-person-form", function () {
  const form = $(this);
  const dialog = form.data("est-estdialog");
  const emailFromOauth = form.find("#email").val();

  form.validate({
    rules: {
      "first_name": {
        required: true,
        minlength: 2,
        maxlength: 31,
      },
      "login": {
        required: true,
        minlength: 5,
        maxlength: 20,
      },
      "email": {
        required: true,
        email: true,
      },
      "phones[]": {
        required: true,
      },
      "password": {
        required: true,
        minlength: 6,
      },
      "password-confirm": {
        required: true,
        equalTo: "#password",
      },
      "agreement": {
        required: true,
      },
    },
    messages: {
      "first_name": {
        required: "Представьтесь, пожалуйста",
        pattern: "Введите текст кириллицей (русские буквы)",
        minlength: "Неужели у вас такое короткое имя?",
        maxlength: "Неужели у вас такое длинное имя?",
      },
      "login": {
        required: "Поле Ваш логин обязательно для заполнения",
        minlength: "Логин не может быть меньше 5 символов",
        maxlength: "Логин не может быть длинее 20 символов",
      },
      "email": {
        required: "Поле Ваш e-mail обязательно для заполнения",
        email: "Поле Ваш e-mail должно быть правильным email адресом",
      },
      "phones[]": {
        required: "Поле Ваш телефон обязательно для заполнения",
      },
      "password": {
        required: "Поле Ваш пароль обязательно для заполнения",
        minlength: "Поле Ваше имя не может быть меньше 6 символов",
      },
      "password-confirm": {
        required: "Поле Повторите пароль обязательно для заполнения",
        equalTo: "Поле Повторите пароль не совпадает с полем Ваш пароль",
      },
      "agreement": {
        required:
          "Мы не можем зарегистрировать Вас пока Вы не согласитесь с условиями пользовательского соглашения",
      },
    },
    errorClass: "errors",
    errorElement: "p",
    ignore: ".ignore",
    errorPlacement: function (error, element) {
      element.parents(".field").append(error);
    },
    submitHandler: function (form) {
      form = $(form);

      const dialog = form.data("est-estdialog");
      const emailWasntChanged = form.find("#email").val() === emailFromOauth;

      form.ajaxSubmit({
        xhrFields: {
          withCredentials: true,
        },
        data: {
          sf_format: "json",
          email_wasnt_changed: emailWasntChanged,
        },
        crossDomain: true,
        dataType: "json",
        cache: false,
        type: "post",
        success: function (data) {
          form.find(".errors").remove();
          if (data.errors) {
            $.each(data.errors, function (i, error) {
              form
                .find('[name="' + i + '"]:first')
                .closest(".field")
                .append(`<div class="errors"><div>${error}</div></div>`);
            });
            form.trigger("est-init-recaptcha");
            dialog && dialog._position(dialog.options.position);
          } else {
            if (true) {
              (window.dataLayer = window.dataLayer || []).push({
                event: "VirtualPageview",
                virtualPageURL: "/registration/person/success/",
              });
            }
            if (
              location.pathname !== "/ru/registration/person/" &&
              location.pathname !== "/ua/registration/person/" &&
              location.pathname !== "/registration/person/"
            ) {
              form.estdialog("close");
            }
            if (data.redirect) {
              location.assign(data.redirect);
            }
          }
        },
      });
    },
  });

  // form.on('cut paste drop input change autocompleteselect autocompletechange', ':input', function (event) {
  form.on("blur", ":input", function (event) {
    $(this)
      .closest(".field")
      .find("p.errors")
      .remove()
      .find("div.errors")
      .remove();

    dialog && dialog._position(dialog.options.position);
  });
});

$(".dialog-signin").estdialog({
  dialogClass: "pseudo-auth-dialog",
  destroyOnClose: true,
  closeOnEscape: false,
  width: 300 + 25 * 2,
  draggable: false,
  autoOpen: true,
  modal: false,
  open: function () {
    setTimeout(function () {
      $("#auth_username").focus();
    }, 1500);
  },
});

$(document).on("click", "a[href*=logout]", function (e) {
  e.preventDefault();
  const href = $(this).attr("href");

  modules.require(["storage"], function (storage) {
    storage.local.removeItem("estmarks");
  });

  document.location = href;
});
