"use strict";

import modules from "ym";

import "./loader_type_css";
import "./loader_type_js";

modules.define(
  "loader_type_all",
  ["loader_type_js", "loader_type_css"],
  function (provide, loader_type_js, loader_type_css) {
    function loader(options) {
      let i;
      let files_arr = [];
      let files_js = options.js || [];
      let files_css = options.css || [];
      let files_img = options.img || [];
      const files_obj = {};
      const cb_success = options.success || null;
      const cb_error = options.error || null;

      files_js = typeof files_js === "string" ? [files_js] : files_js;
      files_css = typeof files_css === "string" ? [files_css] : files_css;
      files_img = typeof files_img === "string" ? [files_img] : files_img;
      files_arr = files_arr
        .concat(files_js)
        .concat(files_css)
        .concat(files_img);

      function queuee_check(key, val) {
        let state;
        let count_nulls = 0;
        let count_trues = 0;
        let count_falses = 0;

        for (let i = 0; i < files_arr.length; i++) {
          state = files_obj[files_arr[i]];

          if (state === true) {
            count_trues++;
          }
          if (state === false) {
            count_falses++;
          }
          if (state === null) {
            count_nulls++;
          }
        }

        if (!count_nulls) {
          if (count_falses) {
            cb_error && cb_error();
          } else {
            cb_success && cb_success();
          }
        }
      }

      function queuee_add(loader, file) {
        files_obj[file] = null;
        loader(
          file,
          function () {
            files_obj[file] = true;
            queuee_check(file, true);
          },
          function () {
            files_obj[file] = false;
            queuee_check(file, false);
          }
        );
      }

      for (i = 0; i < files_js.length; i++) {
        queuee_add(loader_type_js, files_js[i]);
      }

      for (i = 0; i < files_css.length; i++) {
        queuee_add(loader_type_css, files_css[i]);
      }

      if (!files_arr.length) {
        queuee_check();
      }
    }

    provide(loader);
  }
);
