import $ from "jquery";

$(document).on("click", ".app-complaint-form__go-back", function () {
  $(this).closest(".app-complaint-form").attr("data-step", 1);
  $(window).trigger("resize");
});

$(document).on(
  "change click",
  ".app-complaint-form__subjects-list input",
  function () {
    $(this).closest(".app-complaint-form").attr("data-step", 2);
    $(".app-complaint-form__back-step").text($(this).next("span").text());
    $(window).trigger("resize");
  }
);
