// ----------------------------------------------------------------------------
// select2
//

import "./_est-select2.scss";

import $ from "jquery";
import modules from "ym";
import "../../modules/select2";

const assign = Object.assign;

const init_est_select2 = function () {
  $(".est-select2").each(function () {
    let select = $(this);

    if (select.data("select2") != null) {
      return;
    }

    let options = {};
    let col_value = select.attr("data-select2-column-value") || null;
    let col_label = select.attr("data-select2-column-label") || null;

    // data-allow-clear="true"
    // data-minimum-input-length="1"
    // data-placeholder="укажите автора"

    assign(options, {
      // theme: 'est'
      // minimumInputLength: 1,
    });

    select.attr("data-select2-url") &&
      assign(options, {
        // minimumInputLength: 1,
        templateResult: function (item, container) {
          if (item.html) {
            return $(item.html);
          }
          if (item.text) {
            return item.text;
          }
          if (item.id) {
            return item.id;
          }
          return null;
        },
        ajax: {
          dataType: "jsonp",
          cache: true,
          delay: 250,
          url: function (params) {
            // return '/some/url/' + params.term;
            return select.attr("data-select2-url");
          },
          data: function (params) {
            return {
              term: params.term || select.find("option:selected:first").text(),
              page: params.page,
              limit: 1000,
            };
          },
          processResults: function (data, page) {
            return {
              results: $.map(data, function (item, id) {
                let result = {};

                if (col_value && col_value in item) {
                  assign(result, { id: item[col_value] });
                } else if (item.id) {
                  assign(result, { id: item.id });
                } else if (id) {
                  assign(result, { id: id });
                }

                if (col_label && col_label in item) {
                  assign(result, { text: item[col_label] });
                } else if (item.text) {
                  assign(result, { text: item.text });
                } else if (item.label) {
                  assign(result, { text: item.label });
                }

                if (item.html) {
                  assign(result, { html: item.html });
                }

                if (typeof item == "string") {
                  assign(result, { id: id, text: item });
                }

                return result;
              }),
            };
          },
        },
      });

    modules.require(["select2"], function (select2) {
      select2.call(select, options);
    });
  });
};

$(document).on("select2:opening", "select", (e) => {
  if (e.target.hasAttribute("data-readonly")) {
    return false;
  }
});

init_est_select2();

$(document).on("estdialogcreate", function () {
  init_est_select2();
});
