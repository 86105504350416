import modules from "ym";
import "./cors-domains";
import uuid from "../lib/uuid";
import preg_quote from "locutus/php/pcre/preg_quote";

const dummyFunction = () => {};

modules.define("storage", ["cors-domains"], function (provide, cors_domains) {
  const is_supported =
    window.JSON &&
    window.postMessage &&
    window.localStorage &&
    window.sessionStorage;
  let requests = {};

  const on = function (element, event, callback) {
    if (element.addEventListener) {
      element.addEventListener(event, callback, false);
    } else if (element.attachEvent) {
      element.attachEvent("on" + event, callback);
    }
  };

  const origin = {
    extract: function (url) {
      var link;
      var protocol;
      var origin;

      link = document.createElement("a");
      link.href = url;

      if (!link.host) {
        link = window.location;
      }

      if (!link.protocol || link.protocol === ":") {
        protocol = window.location.protocol;
      } else {
        protocol = link.protocol;
      }

      origin = protocol + "//" + link.host;
      origin = origin.replace(/:(80|443)$/, "");

      return origin;
    },
    validate: function (origin) {
      const cors_hosts_regexp = new RegExp(
        `[/.](${cors_domains.map((domain) => preg_quote(domain)).join("|")})$`
      );

      return !!origin.match(cors_hosts_regexp);
    },
  };

  const hub_url = (() => {
    // let res;
    // let file = 'zbz-init-session-storage.php';
    //
    // if ((res = location.href.match(/[/.](est\.ua|estru\.ru|estcrimea\.com)(\/|$)/))) {
    //     return '//est.ua/' + file;
    // }
    // if ((res = location.href.match(/[/.]dev(\d+)\.(thotal\.com|totalr\.biz|mls\.co\.ua)(\/|$)/))) {
    //     return '//dev' + res[1] + '.thotal.com/' + file;
    // }
    // if ((res = location.href.match(/[/.]est\.(branch|trunk)\.(ua|ru|com)(\/|$)/))) {
    //     return '//est.' + res[1] + '.ua/' + file;
    // }
    // return '/' + file;

    // let matches;
    // const cors_hosts_regexp = new RegExp(`[/.](${cors_domains.map(domain => preg_quote(domain)).join('|')})$`);
    //
    // if ((matches = location.href.match(cors_hosts_regexp))) {
    //     return `//${matches[1]}/zbz-init-session-storage.php`;
    // } else {
    //     return `//${cors_domains[0]}/zbz-init-session-storage.php`;
    // }

    return `//${cors_domains[0]}/zbz-init-session-storage.php`;
  })();

  var iframe = (function () {
    var element;
    element = document.createElement("iframe");
    element.setAttribute("src", hub_url);
    element.style.visibility = "hidden";
    element.style.position = "fixed";
    element.style.left = "-999px";
    element.style.top = "-999px";

    return element;
  })();

  var iframe_append = function () {
    if (document.body) {
      document.body.appendChild(iframe);
    } else {
      iframe_append.timeout = setTimeout(iframe_append, 10);
    }
  };

  var do_postmessage_request = function (data, callback) {
    var id = uuid();

    requests[id] = {
      callback: callback,
      data: data,
    };

    iframe.contentWindow.postMessage(
      JSON.stringify({
        id: id,
        data: data,
      }),
      origin.extract(hub_url)
    );
  };

  var storage = {
    session: {
      setItem: function (key, val, callback = dummyFunction) {
        do_postmessage_request(
          {
            scope: "session",
            method: "setItem",
            params: [key, val],
          },
          callback
        );
      },
      getItem: function (key, callback = dummyFunction) {
        do_postmessage_request(
          {
            scope: "session",
            method: "getItem",
            params: [key],
          },
          callback
        );
      },
      removeItem: function (key, callback = dummyFunction) {
        do_postmessage_request(
          {
            scope: "session",
            method: "removeItem",
            params: [key],
          },
          callback
        );
      },
    },
    local: {
      setItem: function (key, val, callback = dummyFunction) {
        do_postmessage_request(
          {
            scope: "local",
            method: "setItem",
            params: [key, val],
          },
          callback
        );
      },
      getItem: function (key, callback = dummyFunction) {
        do_postmessage_request(
          {
            scope: "local",
            method: "getItem",
            params: [key],
          },
          callback
        );
      },
      removeItem: function (key, callback = dummyFunction) {
        do_postmessage_request(
          {
            scope: "local",
            method: "removeItem",
            params: [key],
          },
          callback
        );
      },
    },
  };

  is_supported &&
    on(iframe, "load", function () {
      provide(storage);
    });

  is_supported &&
    on(window, "message", function (event) {
      if (origin.validate(event.origin)) {
        if (iframe.contentWindow && iframe.contentWindow == event.source) {
          var request;
          var response = {};
          try {
            response = JSON.parse(event.data);
          } catch (e) {
            response = {};
          }
          if (response.id && requests[response.id]) {
            request = requests[response.id];
            request.callback && request.callback.call(storage, response.data);
            delete requests[response.id];
          }
        }
      }
    });

  is_supported && iframe_append();
});
