// ----------------------------------------------------------------------------
// стили

import "./components/_base.scss";
import "./components/_lazy.scss";
import "./blocks/est-easy-html/_est-easy-html.scss";
import "./components/_shields.scss";
import "./components/_ui.scss";
import "./components/_est-swap-vr.scss";
import "./components/_layout.scss";
import "./components/_vcard.scss";
import "./components/_vcard-head.scss";
import "./components/_vcard-menu.scss";
import "./components/app-complex/_appcomplex-head.scss";
import "./components/app-complex/_appcomplex-menu.scss";
import "./components/app-complex/_novostrojka-offer.scss";
import "./components/_icons.scss";
import "./components/_style.scss";
import "./components/_est-form-div.scss";
import "./components/_est-form-table.scss";
import "./components/_subscribe-form.scss";
import "./components/_est-block.scss";
import "./components/est-ajax-load/_est-ajax-load.scss";
import "./vendor/_swiper.scss";
import "./blocks/est-map/_est-static-map.scss";
import "./blocks/est-list/_est-list.scss";
import "./blocks/est-checkmark/_est-checkmark.scss";
import "./blocks/est-currency-selector/_est-currency-selector.scss";
import "./blocks/est-logo/_est-logo.scss";
import "./blocks/est-my-money/_est-my-money.scss";
import "./blocks/form-search/_form-search.scss";
import "./blocks/est-widget-array/_est-widget-array.scss";
import "./blocks/est-auser-item/_est-auser-item.scss";
import "./blocks/est-file/_est-file.scss";
import "./blocks/est-embed/_est-embed.scss";
import "./blocks/profi-ad-bar/_profi-ad-bar.scss";
import "./blocks/profi-packet-reminder/_profi-packet-reminder.scss";
import "./blocks/profi-status-form/_profi-status-form.scss";
import "./blocks/add-worker-form/_add-worker-form.scss";
import "./blocks/est-checkbox-tree/_est-checkbox-tree.scss";
import "./components/_est-counter.scss";
import "./components/_est-scroll-area.scss";
import "./blocks/article-form/_article-form.scss";
import "./blocks/est-flash-message/_est-flash-message.scss";
import "./blocks/mobile-navbar/_mobile-navbar.scss";
import "./blocks/main-menu/main-menu.scss";
import "./components/_found-count.scss";
import "./components/_est-sort.scss";
import "./components/_est-filter.scss";
import "./components/_est-breadcrumbs.scss";
import "./components/_leads.scss";
import "./components/_paging.scss";
import "./components/_filter.scss";
import "./components/top3/top3.scss";
import "./components/_dialog_group.scss";
import "./components/_apps-filter-form.scss";
import "./components/special-offer/_special-offer.scss";
import "./components/_currencies.scss";
import "./components/_land_after_publish.scss";
import "./components/_guideline_info.scss";
import "./components/_apps-theme-switcher.scss";
import "./components/eo-list/_eo-list.scss";
import "./components/_eo_list_table.scss";
import "./components/_eo_list_gallery.scss";
import "./components/_eo_hot_and_up.scss";
import "./components/_eo_crm.scss";
import "./components/_eo_similar.scss";
import "./components/_eo_balloon.scss";
import "./components/_images_preload.scss";
import "./components/_message-form.scss";
import "./components/_advlinks.scss";
import "./components/_wikilinks.scss";
import "./components/_dropdown-support.scss";
import "./components/_footer-menu.scss";
import "./components/_footer-commercial-tabs.scss";
import "./components/_footer-offers.scss";
import "./components/_banner-slot.scss";
import "./components/_fly-buttons.scss";
import "./components/_app-profi-info.scss";
import "./components/_article-item.scss";
import "./components/_article-list.scss";
import "./components/_article-related.scss";
import "./components/_lead-form.scss";
import "./components/_app-complaint-form.scss";
import "./components/_app-sendmail-form.scss";
import "./components/_app-sendmail-show.scss";
import "./components/_est-sitemap.scss";
import "./components/_est-object-block.scss";
import "./components/_content-image.scss";
import "./components/_promo-pakets.scss";
import "./components/_promo-novostrojka-pakets.scss";
import "./components/_flow-message.scss";
import "./components/_flow-panel.scss";
import "./components/_flow-item.scss";
import "./components/_pager_table.scss";
import "./components/_pager-table.scss";
import "./components/_adpopup-dialog.scss";
import "./components/_app-stars.scss";
import "./components/_auser-stars.scss";
import "./components/_auser-grid.scss";
import "./utilities/_borders.scss";
import "./utilities/_embeds.scss";
import "./utilities/_float.scss";
import "./utilities/_sizing.scss";
import "./utilities/_text.scss";
import "./utilities/_tailwind.scss";

// ----------------------------------------------------------------------------

import { waitForSelectorOnce } from "./lib/wait-for-selector";

import "./lib/store-utm"; // сохраняем utm метки в куках
import "./components/noembed"; // боремся со встраиванием эста
import "./components/sso"; // удаление сессионных параметров из урла
import "./components/est-language"; // редирект на предпочитаемый язык

// ----------------------------------------------------------------------------
// jquery + ui + plugins

import "./lib/jquery-ui.js";
import "jquery-validation/dist/jquery.validate";
import "jquery-validation/dist/localization/messages_ru.js";
import "jquery-form";

// ----------------------------------------------------------------------------

import "./modules/jquery";
import "./modules/c8_show_code";
import "./modules/cookies";
import "./modules/googletag";
import "./modules/hash";
import "./modules/jquery-scrollto";
import "./modules/loader_type_all";
import "./modules/loader_type_css";
import "./modules/loader_type_js";
import "./modules/onload";
import "./modules/recaptcha";
import "./modules/storage";
import "./modules/ajax-dialog";
import "./modules/datepicker";
import "./modules/fancytree"; // используется только в админке, но перенести туда не можем, т.к. est-queuee у нас здесь
import "./modules/tablesorter"; // используется только в админке, но перенести туда не можем, т.к. est-queuee у нас здесь

// ----------------------------------------------------------------------------

import "./components/jquery-helpers";
import "./components/jquery.validator.js";
import "./components/jquery.serializeObject.js";
import "./components/est-unload/est-unload.js";
import "./components/est-dialog/est-dialog";
import "./components/data-method.js";
import "./components/data-target.js";
import "./components/data-ping-click.js";
import "./components/data-track-click";
import "./components/auth/auth";
import "./components/est-popup.js";
import "./components/data-scroll-to.js";
import "./components/est-load-script.js";
import "./components/est-dropdown/est-dropdown.js";
import "./components/endless-scroll/endless-scroll.js";
import "./components/est-ajax-load/est-ajax-load.js";
import "./components/est-mouse-scroll.js";
import "./blocks/est-switcher-panel/est-switcher-panel.js";
import "./components/command-close-window.js";
import "./components/command-reload.js";
import "./blocks/est-steps/est-steps.js";
import "./blocks/est-details/est-details.js";
import "./blocks/est-select2/est-select2.js";
import "./blocks/est-currency-selector/est-currency-selector.js";
import "./blocks/est-carousel/est-carousel.js";
import "./blocks/est-widget-array/est-widget-array.js";
import "./components/est-widget-ajax-files/est-widget-ajax-files.js";
import "./components/est-input-clearable.js";
import "./components/est-input-translate.js";
import "./components/est-filter.js";
import "./components/input-attr-data-input-pattern.js";
import "./blocks/rating-site/rating-site.js";
import "./components/app-complaint-form.js";
import "./components/comment-block/comment-block.js";
import "./components/comment-item/comment-item.js";
import "./components/comment-form/comment-form.js";
import "./components/article/article.js";
import "./components/data-click-hashbang.js";
import "./components/est-queuee";
import "./components/est-lazy-load/est-lazy-load";
import "./components/recaptcha";
import "./components/est-social-share/est-social-share";
import "./components/vcard-scroller/vcard-scroller";
import "./modules/codemirror.js";

// ----------------------------------------------------------------------------

// https://estua.megaplan.ru/task/1011233/card/
window.addEventListener(
  "touchstart",
  function onFirstTouch() {
    modules.require(["loader_type_all"], (loader) => {
      loader({
        js:
          "https://cdn.jsdelivr.net/npm/jquery-ui-touch-punch@0.2.3/jquery.ui.touch-punch.min.js",
      });
    });
    window.removeEventListener("touchstart", onFirstTouch, false);
  },
  false
);

// ----------------------------------------------------------------------------

((deps) => {
  Object.entries(deps).forEach(([selector, loader]) => {
    waitForSelectorOnce(selector).then(() => {
      console.log(`lazylib: "${selector}" found`);
      loader().then(() => {
        console.log(`lazylib: "${selector}" loaded`);
      });
    });
  });
})({
  ".est-stick": () => import("./blocks/est-stick.js"),
  ".fotorama": () => import("./blocks/est-fotorama/est-fotorama.js"),
  ".est-vue": () => import("./components/est-vue/est-vue"),
  ".fb-comments, .fb-video": () => import("./components/facebook-sdk.js"),
  "[data-fancybox]": () => import("./components/data-fancybox"),
  'input[type="tel"]': () => import("./components/input-type-tel-mask.js"),
  ".b-select-location-quick": () =>
    import("./components/select-location-quick/select-location-quick.js"),
  "[data-auser-authenticity], [data-app-authenticity]": () =>
    import("./components/authenticity-popup.js"),
  ".estmark-toggle": () => import("./components/estmark/estmark.js"),
  ".sp-look": () => import("./blocks/sp-look/sp-look.js"),
  ".est-textarea-autoheight": () =>
    import("./blocks/est-textarea-autoheight/est-textarea-autoheight.js"),
  "[data-codemirror]": () => import("./components/data-codemirror"),
});

// ----------------------------------------------------------------------------
