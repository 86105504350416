"use strict";

import $ from "jquery";
import getSelection from "../lib/get-selection";
import getTextFromClipboard from "../lib/get-text-from-clipboard";

/**
 * based on https://github.com/Mavrin/maskInput
 *
 * works only on text|search|password|tel|url inputs and textareas
 * e.g. doesn't work on number input
 */
(function (document) {
  const $document = $(document);
  const attr = "data-input-pattern";
  const selector = "[" + attr + "]";

  const support_paste = (() => {
    const input = document.createElement("input");
    input.setAttribute("type", "text");
    return "onpaste" in input;
  })();

  const getTextFromDrop = function (event) {
    let text = null;

    event.originalEvent &&
      event.originalEvent.dataTransfer &&
      $.each(event.originalEvent.dataTransfer.types, function (i, type) {
        if (type === "text/plain" || type === "Text") {
          text = event.originalEvent.dataTransfer.getData(type);
        }
      });

    return text;
  };

  // Ctrl+v
  const onKeyDown = function (e) {
    return !((e.ctrlKey || e.altKey || e.metaKey) && e.which === 86);
  };

  const onContextMenu = function () {
    return false;
  };

  const onPaste = function (event) {
    const pattern = $(event.target).attr(attr);
    const selection = getSelection(event.target);
    const text = getTextFromClipboard(event);
    const value_old = event.target.value;
    const value_new =
      value_old.substring(0, selection.start) +
      text +
      value_old.substring(selection.end);

    try {
      return new RegExp(pattern).test(value_new);
    } catch (e) {
      return true;
    }
  };

  const onDrop = function (event) {
    const pattern = $(event.target).attr(attr);
    const selection = getSelection(event.target);
    const text = getTextFromDrop(event);
    const value_old = event.target.value;
    const value_new =
      value_old.substring(0, selection.start) +
      text +
      value_old.substring(selection.end);

    try {
      return new RegExp(pattern).test(value_new);
    } catch (e) {
      return true;
    }
  };

  const onKeyPress = function (e) {
    if (
      e.ctrlKey ||
      e.altKey ||
      e.metaKey ||
      (e.which < 32 && e.which !== 13)
    ) {
      return true;
    }

    if (e.which === 13 && $(e.target).is("input")) {
      return true;
    }

    if (e.which) {
      const pattern = $(e.target).attr(attr);
      const selection = getSelection(e.target);
      const text = e.which === 13 ? "\n" : String.fromCharCode(e.which);
      const value_old = e.target.value;
      const value_new =
        value_old.substring(0, selection.start) +
        text +
        value_old.substring(selection.end);

      try {
        return new RegExp(pattern).test(value_new);
      } catch (e) {
        return true;
      }
    }

    return true;
  };

  // $document.on('contextmenu', selector, onContextMenu);
  $document.on("drop", selector, onDrop);
  $document.on("paste", selector, onPaste);
  $document.on("keydown", selector, support_paste ? $.noop : onKeyDown);
  $document.on("keypress", selector, onKeyPress);
})(document);
