import $ from "jquery";

export const isMultiuploadSupported = (function () {
  var input = $('<input type="file" />').get(0);

  var nav = navigator.appVersion;
  var is_win_safari =
    nav.indexOf("Win") != -1 &&
    nav.indexOf("Safari") != -1 &&
    nav.indexOf("Version/") != -1;
  var support_files = "multiple" in input && "files" in input;
  var support_upload = typeof new XMLHttpRequest().upload != "undefined";

  return support_files && support_upload && !is_win_safari;
})();

function upload_file_xhr(file, extradata, options) {
  var data = new FormData();
  data.append("file", file);

  $.each(extradata || {}, function (key, val) {
    data.append(key, val);
  });

  var params = $.extend(true, {}, $.ajaxSettings, options, {
    contentType: false,
    processData: false,
    cache: false,
    type: "POST",
  });

  if (options.progress) {
    params.xhr = function () {
      var xhr = jQuery.ajaxSettings.xhr();
      if (xhr.upload) {
        xhr.upload.onprogress = function (event) {
          var total = event.total;
          var loaded = event.loaded || event.position;
          var percent = event.lengthComputable
            ? Math.ceil((loaded / total) * 100)
            : 0;
          options.progress(event, loaded, total, percent);
        };
      }
      return xhr;
    };
  }

  var beforeSend = params.beforeSend;

  params.beforeSend = function (xhr, o) {
    o.data = data;

    if (beforeSend) {
      beforeSend.call(this, xhr, o);
    }
  };

  $.ajax(params);
}

function upload_file_iframe(file, extradata, options) {
  var form;
  var input;

  file.name = "file";

  form = document.createElement("form");
  form.setAttribute("method", "post");
  form.setAttribute("enctype", "multipart/form-data");
  form.setAttribute("encoding", "multipart/form-data");
  form.setAttribute(
    "style",
    "border: 1px solid red; padding: 30px; background: #fff; position: fixed; left: 0; top: 0;"
  );
  form.appendChild(file);

  $.each(extradata || {}, function (key, val) {
    input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", key);
    input.setAttribute("value", val);
    form.appendChild(input);
  });

  $(form)
    .hide()
    .appendTo(document.body)
    .ajaxSubmit(
      $.extend({}, options, {
        complete: function () {
          if (options.complete) {
            options.complete.apply(this, arguments);
          }

          $(form).remove();
        },
      })
    );
}

export const uploadTempFile = function (file, extradata, options) {
  if (isMultiuploadSupported) {
    upload_file_xhr(file, extradata, options);
  } else {
    upload_file_iframe(file, extradata, options);
  }
};
