// ----------------------------------------------------------------------------
// панелька переключателей

import "./est-switcher-panel.scss";

import $ from "jquery";
import scrollto from "../../lib/scrollto";
import listenToMediaQuery from "../../lib/listen-to-media-query";

const scrollSwitchersToSelectedItems = () => {
  [...document.querySelectorAll(".page-menu .est-switcher-panel")].forEach(
    (panel) => {
      const selected = $(panel).find(".est-switcher-panel__item--selected");
      const distance = (-1 * (window.innerWidth - selected.width())) / 2;
      scrollto(panel, ".est-switcher-panel__item--selected", {
        duration: 100,
        axis: "x",
        offset: {
          top: 0,
          left: distance,
        },
      });
    }
  );

  [...document.querySelectorAll(".page-menu .switcher")].forEach((panel) => {
    const selected = $(panel).find(".selected");
    const distance = (-1 * (window.innerWidth - selected.width())) / 2;
    scrollto(panel, ".selected", {
      duration: 100,
      axis: "x",
      offset: {
        top: 0,
        left: distance,
      },
    });
  });
};

const getGeometry = (element) => ({
  right: "auto",
  top: element.position().top,
  left: element.position().left,
  width: element.outerWidth(),
});

const onClick = function () {
  var selected;
  var selector;
  var target = $(this);

  if (target.is(".est-dropdown-toggler")) {
    return true;
  }

  if (target.is('[target="_blank"]')) {
    return true;
  }

  var list = target.closest(".est-switcher-panel");
  var item = target.closest(".est-switcher-panel__item");
  var li = target.closest("li:not(.est-switcher-panel__item)");

  selected = list.find(".est-switcher-panel__item--selected:first");
  selector = list.find(".est-switcher-panel__item--selector:first");
  selector = selector.length
    ? selector
    : $(
        '<li class="est-switcher-panel__item est-switcher-panel__item--selector" />'
      ).prependTo(list);

  selector.css(selected.length ? getGeometry(selected) : {});
  selected.is(item) || selector.show();

  list.find(".selected").not(li).removeClass("selected");
  list
    .find(".est-switcher-panel__item--selected")
    .not(item)
    .removeClass("est-switcher-panel__item--selected");

  selected = item;

  selected.trigger("beforeselect");
  selector.animate(getGeometry(item), 100, "linear", function () {
    li.addClass("selected");
    selected.addClass("est-switcher-panel__item--selected");
    selector.hide();
    target.trigger("select");
  });
};

$(document)
  .on(
    "click",
    ".est-switcher-panel .est-switcher-panel__item:not(.est-switcher-panel__item--selected) > a",
    onClick
  )
  .on(
    "click",
    ".est-switcher-panel .est-switcher-panel__item:not(.est-switcher-panel__item--selected) > span",
    onClick
  )
  .on(
    "click",
    ".est-switcher-panel .est-switcher-panel__item li:not(.selected) > a",
    onClick
  )
  .on(
    "click",
    ".est-switcher-panel .est-switcher-panel__item li:not(.selected) > span",
    onClick
  );

listenToMediaQuery("screen and (min-width: 992px)", (matches) => {
  matches || scrollSwitchersToSelectedItems();
});
