import qs from "qs";
import modules from "ym";

const api_url = "//www.google.com/recaptcha/api.js";
const callback_name = "est_recaptcha_onload";
const lang = document.documentElement.getAttribute("lang") || "ru";

modules.define("recaptcha", ["loader_type_all"], function (provide, loader) {
  window[callback_name] = function () {
    if (typeof grecaptcha !== "undefined") {
      provide(grecaptcha);
    }
  };

  loader({
    js:
      api_url +
      "?" +
      qs.stringify({
        onload: callback_name,
        render: "explicit",
        hl: lang,
      }),
  });
});
