import "./est-carousel.scss";
import "./professionals-slider.scss";

import $ from "jquery";
import modules from "ym";
import "../../modules/jcarousel";

$(document).on("est-carousel:init", function () {
  if ($(".est-carousel").length) {
    modules.require(["jcarousel"], () => {
      $(
        ".est-carousel:not(.est-carousel--delayed):not(.est-carousel--inited)"
      ).each(function () {
        var block = $(this).addClass("est-carousel--inited");
        var element_clip = block.find(".est-carousel__clip");
        var element_prev = block.find(".est-carousel__prev");
        var element_next = block.find(".est-carousel__next");
        var element_current = block.find(".est-carousel__caption-current");
        var element_total = block.find(".est-carousel__caption-total");
        var element_prev_class_inactive = "est-carousel__prev--inactive";
        var element_next_class_inactive = "est-carousel__next--inactive";
        var wrap = null;

        wrap = block.is(".est-carousel--wrap-both") ? "both" : wrap;
        wrap = block.is(".est-carousel--wrap-circular") ? "circular" : wrap;

        element_clip.jcarousel({
          wrap: wrap,
        });

        element_prev
          .on("jcarouselcontrol:active", function () {
            element_prev.removeClass(element_prev_class_inactive);
          })
          .on("jcarouselcontrol:inactive", function () {
            element_prev.addClass(element_prev_class_inactive);
          })
          .jcarouselControl({
            target: "-=" + (parseInt(element_prev.attr("data-step")) || 1),
            carousel: element_clip,
          });

        element_next
          .on("jcarouselcontrol:active", function () {
            element_next.removeClass(element_next_class_inactive);
          })
          .on("jcarouselcontrol:inactive", function () {
            element_next.addClass(element_next_class_inactive);
          })
          .jcarouselControl({
            target: "+=" + (parseInt(element_next.attr("data-step")) || 1),
            carousel: element_clip,
          });

        element_clip.on("jcarousel:scrollend", function (event, carousel) {
          element_current.text(element_clip.jcarousel("target").data("step"));
        });

        element_total.text(element_clip.jcarousel("items").length);
        element_current.text(1);
      });
    });
  }
});

$(function () {
  $(document).trigger("est-carousel:init");
});
