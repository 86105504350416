// ----------------------------------------------------------------------------
// Открытие ссылки в новом окне с указанным размером

import $ from "jquery";

$(document).on(
  "click",
  "a[href][data-popup], [data-href][data-popup]",
  function (event) {
    let link = $(this);
    let data = link.attr("data-popup");
    let href = link.attr("href") || link.attr("data-href");
    let popup;
    let scr;
    let win;

    if (link.is(".link-login")) {
      return true;
    }

    if (data.match(/\d+[x]\d+/)) {
      event.preventDefault();
      scr = {
        width: screen.availWidth,
        height: screen.availHeight,
      };
      win = {
        width: data.split("x")[0],
        height: data.split("x")[1],
      };
      win.width = Math.min(scr.width, win.width);
      win.height = Math.min(scr.height, win.height);

      popup = window.open(
        href,
        "",
        [
          "left=" + (scr.width - win.width) / 2,
          "top=" + (scr.height - win.height) / 2,
          "width=" + win.width,
          "height=" + win.height,
          "scrollbars=yes",
        ].join(",")
      );

      if (!popup.closed && popup.moveTo) {
        popup.moveTo(
          (scr.width - win.width) / 2,
          (scr.height - win.height) / 2
        );
      }
      !popup.closed && popup.resizeTo && popup.resizeTo(win.width, win.height);
      !popup.closed && popup.focus && popup.focus();
    }
  }
);
