import $ from "jquery";

/**
 * Ссылки и формы с классом est-load-script приводят к загрузке и выполнению js скрипта
 */
$(document).on("click submit", ".est-load-script", function (event) {
  const link = $(this);
  const href =
    link.attr("action") || link.attr("href") || link.attr("data-href");

  if (link.is("form") && event.type != "submit") {
    return;
  }

  if (link.is("form") && !link.attr("novalidate") && this.checkValidity) {
    if (!this.checkValidity()) {
      return false;
    }
  }

  if (!$.data(document, "ajax-send")) {
    $.data(document, "ajax-send", true);

    const ajax_data = {
      dataType: "script",
      overlay: true,
      data: {
        sf_format: "script",
      },
      complete: function () {
        $.data(document, "ajax-send", false);
      },
    };

    if (link.is("form")) {
      link.ajaxSubmit(ajax_data);
    } else {
      $.ajax({
        ...ajax_data,
        url: href,
      });
    }
  }

  return false;
});
