"use strict";

import modules from "ym";

/**
 * @returns Element
 */
function findInsertPointElement() {
  const links = Array.from(
    document.querySelectorAll(
      'link[rel="stylesheet"], script[src]:not([async]):not([defer])'
    )
  );

  if (links.length) {
    return links[links.length - 1];
  } else {
    return document.querySelector("script");
  }
}

function insertLinkElement(link) {
  const insertPointElement = findInsertPointElement();
  if (insertPointElement.after) {
    insertPointElement.after(link);
  } else if (insertPointElement.parentNode && insertPointElement.nextSibling) {
    insertPointElement.parentNode.insertBefore(
      link,
      insertPointElement.nextSibling
    );
  } else if (insertPointElement.parentNode) {
    insertPointElement.parentNode.appendChild(link);
    // insertPointElement.parentNode.insertBefore(link, insertPointElement);
  }
}

modules.define("loader_type_css", function (provide) {
  const loading = {};
  const loaded = {};

  const runCallbacks = function (url, type) {
    var cbs = loading[url];
    var cb;
    var i = 0;
    delete loading[url];
    while ((cb = cbs[i++])) {
      cb[type] && cb[type]();
    }
  };

  const onSuccess = (url) => {
    loaded[url] = true;
    runCallbacks(url, "success");
  };

  const onError = (url) => {
    runCallbacks(url, "error");
  };

  provide((url, success, error) => {
    url =
      (location.protocol === "file:" && 0 === url.indexOf("//")
        ? "http:"
        : "") + url;

    if (loaded[url]) {
      success && success();
      return;
    }

    if (loading[url]) {
      loading[url].push({
        success: success,
        error: error,
      });
      return;
    }

    loading[url] = [
      {
        success: success,
        error: error,
      },
    ];

    var status = null;
    var is_fallback;
    var link;
    var img;

    link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = url;

    // https://github.com/filamentgroup/loadCSS/blob/master/loadCSS.js#L20
    link.media = "only x";

    const onloadcssdefined = (cb) => {
      let href = link.href;
      let i = document.styleSheets.length;
      while (i--) {
        if (document.styleSheets[i].href === href) {
          return cb();
        }
      }
      setTimeout(() => {
        onloadcssdefined(cb);
      });
    };

    // once loaded, set link's media back to `all` so that the stylesheet applies once it loads
    onloadcssdefined(() => {
      link.media = "all";
    });

    is_fallback = !("onerror" in link);

    if (is_fallback) {
      link.onload = () => {
        link.onload = link.onerror = null;
        status = true;
      };
    } else {
      link.onload = () => {
        link.onload = link.onerror = null;
        status = true;
        onSuccess(url);
      };
      link.onerror = () => {
        link.onload = link.onerror = null;
        status = false;
        onError(url);
      };
    }

    insertLinkElement(link);

    if (is_fallback) {
      img = document.createElement("img");
      img.onerror = () => {
        img.onerror = null;
        if (status === true) {
          onSuccess(url);
        } else {
          onError(url);
        }
      };

      img.src = url;
    }
  });
});
