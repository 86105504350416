import "./_est-steps.scss";

import on from "../../lib/on.js";
import closest from "../../lib/closest.js";

on("click", document, ".est-steps__goto", function () {
  const button = this;
  const container = closest(button, ".est-steps");

  if (container) {
    let max = parseInt(container.getAttribute("data-step-max")) || 0;
    let cur = parseInt(container.getAttribute("data-step")) || 0;
    let pos = (button.getAttribute("data-step") || "").trim();
    let min = 0;
    let re;

    max = max > min ? max : min;
    cur = cur > max ? max : cur;
    cur = cur < min ? min : cur;
    pos = /^[+-]+0$/.exec(pos) ? cur : pos;
    pos = pos === "next" ? "+1" : pos;
    pos = pos === "prev" ? "-1" : pos;
    pos = pos === "min" ? min : pos;
    pos = pos === "max" ? max : pos;
    pos = max > min ? pos : min;

    if ((re = /^\+\+(\d+)$/.exec(pos))) {
      pos = cur + parseInt(re[1]);
      while (pos > max - min) {
        pos = pos - (max - min + 1);
      }
    }

    if ((re = /^\-\-(\d+)$/.exec(pos))) {
      pos = cur - parseInt(re[1]);
      while (pos < min) {
        pos = pos + (max - min + 1);
      }
    }

    if ((re = /^\+(\d+)$/.exec(pos))) {
      pos = cur + parseInt(re[1]);
      pos = pos > max ? max : pos;
    }

    if ((re = /^\-(\d+)$/.exec(pos))) {
      pos = cur - parseInt(re[1]);
      pos = pos < min ? min : pos;
    }

    if ((re = /^(\d+)$/.exec(pos))) {
      pos = parseInt(re[1]);
      pos = pos > max ? max : pos;
      pos = pos < min ? min : pos;
    }

    container.setAttribute("data-step", pos);
  }
});
