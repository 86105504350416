import $ from "jquery";

$.fn.serializeObject = function () {
  let src = $(this).serializeArray();
  let res = {};

  for (let i = 0; i < src.length; i++) {
    let key = src[i].name;
    let val = src[i].value;

    if (key.match(/\[\]$/)) {
      key = key.replace(/\[\]$/, "");

      if (typeof res[key] == "undefined") {
        res[key] = [];
      }

      if ($.inArray(val, res[key]) === -1) {
        res[key].push(val);
      }
    } else {
      if (typeof res[key] == "undefined") {
        res[key] = val;
      }

      res[key] = val;
    }
  }

  return res;
};
