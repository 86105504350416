"use strict";

import $ from "jquery";

function update_names(list) {
  let i = 0;

  list.find(".est-widget-array__item").each(function () {
    const item = $(this);

    item.find(":input").each(function () {
      const input = $(this);
      const name = input.attr("name").replace(/\[\d*\]/, `[${i}]`);
      input.attr("name", name);
    });

    i++;
  });
}

/**
 * виджет для множественных инпутов формы
 */
$(document).on(
  "click",
  ".est-widget-array__item:last-child .est-widget-array__item-btn-add",
  function () {
    const btn = $(this);
    const item = btn.closest(".est-widget-array__item");
    const list = item.closest(".est-widget-array");
    const input = item.find(":input");
    const maxCount = list.data("max-count");
    const items = list.find(".est-widget-array__item");

    if (input.val()) {
      const item_new = item.clone();
      const input_new = item_new.find(":input");

      input_new.removeAttr("readonly");
      input_new.removeAttr("value");
      input_new.val([]);

      list.append(item_new);

      if (maxCount && items.length >= maxCount - 1) {
        item_new.find(".est-widget-array__item-btn-add").hide();
        items.find(".est-widget-array__item-btn-add").hide();
      }

      update_names(list);
    }
  }
);

$(document).on(
  "click",
  ".est-widget-array__item:not(:only-child) .est-widget-array__item-btn-del",
  function () {
    const item = $(this).closest(".est-widget-array__item");
    const list = item.closest(".est-widget-array");

    setTimeout(() => {
      item.remove();

      const items = list.find(".est-widget-array__item");
      const maxCount = list.data("max-count");
      if (maxCount && items.length <= maxCount - 1) {
        items.last().find(".est-widget-array__item-btn-add").show();
      }

      update_names(list);
    }, 0);
  }
);
