import modules from "ym";

import "./jquery";
import "./loader_type_all";

const versions = require("./versions.json");
const basepath = `https://cdnjs.cloudflare.com/ajax/libs/jquery.tablesorter/${versions.tablesorter}`;

modules.define(
  "tablesorter",
  ["loader_type_all", "jquery"],
  (provide, loader, $) => {
    loader({
      js: basepath + "/js/jquery.tablesorter.min.js",
      success() {
        loader({
          js: basepath + "/js/jquery.tablesorter.widgets.min.js",
          success() {
            if (typeof $.fn.tablesorter !== "undefined") {
              provide(function (elements, options) {
                $.fn.tablesorter.call(
                  $(elements),
                  Object.assign({ theme: "est" }, options || {})
                );
              });
            }
          },
        });
      },
    });
  }
);
