import "./_rename-form.scss";
import "./_est-widget-ajax-files.scss";
import "./_est-widget-ajax-images.scss";

import $ from "jquery";
import "jquery-ui/ui/widgets/sortable.js";
import {
  isMultiuploadSupported,
  uploadTempFile,
} from "../../lib/upload-temp-file";
import suggest_mime_type_by_filename from "../../lib/suggest-mime-type";

let in_rename = false;
const rename_form = $('<div class="rename-form" />');

rename_form.on("mousedown", function (event) {
  event.stopPropagation(event);
});

rename_form.on("click", ".btn-cancel", function () {
  rename_form.find(":input").val([]);
  rename_form.detach();
  in_rename = false;
});

rename_form.on("click", ".btn-accept", function () {
  const name = $.trim(rename_form.find(":input").val());
  const inp_rename = in_rename.find(':input[type="hidden"], textarea[hidden]');
  const btn_rename = in_rename.find(".button-rename");
  const field = inp_rename.closest(
    ".est-widget-ajax-files, .est-widget-ajax-images"
  );

  inp_rename.val(name).trigger("change");
  btn_rename.text(name || field.attr("data-rename-text") || "");

  rename_form.find(":input").val([]);
  rename_form.detach();
  in_rename = false;
});

$(function () {
  $(".est-widget-ajax-files, .est-widget-ajax-images").each(function () {
    let field = $(this);
    if (field.attr("data-sortable") == 1) {
      field.sortable({
        handle:
          ".est-widget-ajax-files__file-wrap, .est-widget-ajax-images__file-wrap",
        items:
          '.file-item[data-state="filled"], .file-item[data-state="rotate"]',
        // cancel: '.file-item:not([data-state="filled"])',
        placeholder: "file-item ui-sortable-placeholder",
        start: function (e, ui) {
          var handle = ui.item.find(
            ".est-widget-ajax-files__file-wrap, .est-widget-ajax-images__file-wrap"
          );
          ui.placeholder
            .width(handle.outerWidth())
            .height(handle.outerHeight());
        },
      });
    }
  });
});

$(document).on("mousedown", function () {
  if (in_rename) {
    rename_form.find(".btn-cancel").click();
  }
});

$(document).on(
  document.all ? "click" : "change",
  ".est-widget-ajax-files :input:file, .est-widget-ajax-images :input:file",
  function (event) {
    const field = $(this).closest(
      ".est-widget-ajax-files, .est-widget-ajax-images"
    );
    var fname = field.attr("data-name");
    var accept = field.attr("data-accept") || "*/*";
    var self = this;
    var input = $(self);
    var wrap = input.closest(
      ".est-widget-ajax-files__file-wrap, .est-widget-ajax-images__file-wrap"
    );
    var type = null;

    setTimeout(function () {
      wrap
        .empty()
        .append(
          `<input type="file" name="${fname}[]" accept="${accept}"${
            isMultiuploadSupported ? ' multiple="multiple"' : ""
          } />`
        );

      var empty = field.find('.file-item[data-state="empty"]');
      var files = isMultiuploadSupported ? self.files : [self];
      var count = Math.min(empty.length, files.length);
      var re = new RegExp(accept.replace("*", ""));

      for (var i = 0; i < count; i++) {
        if (isMultiuploadSupported) {
          type = files[i].type || files[i].fileType || "";
        } else {
          type = suggest_mime_type_by_filename(files[i].value);
        }

        type.match(re) &&
          $(empty.get(i))
            .attr("data-state", "queuee")
            .find(
              ".est-widget-ajax-files__file-wrap, .est-widget-ajax-images__file-wrap"
            )
            .data("file", files[i]);
      }

      field.trigger("queuee");
    });
  }
);

$(document).on(
  "queuee",
  ".est-widget-ajax-files, .est-widget-ajax-images",
  function (event) {
    const field = $(this).closest(
      ".est-widget-ajax-files, .est-widget-ajax-images"
    );
    const fname = field.attr("data-name");
    const limit_upload = 3;
    const items_all = $(this).find(".file-item");
    const items_progress = items_all.filter('[data-state="progress"]');
    const items_queuee = items_all.filter(
      '[data-state="queuee"]:lt(' + (limit_upload - items_progress.length) + ")"
    );

    items_queuee.each(function (i, fileitem) {
      const item = $(fileitem).attr("data-state", "progress");
      const wrap = item.find(
        ".est-widget-ajax-files__file-wrap, .est-widget-ajax-images__file-wrap"
      );
      const file = wrap.data("file");
      const ajaxopts = {
        dataType: "json",
        url: "/__ajax/upload_temp_file.php",
        progress: function (event, loaded, total, percent) {
          wrap.attr("data-message", percent + "%");
        },
        error: function () {
          item
            .find(
              ".est-widget-ajax-files__button-delete, .est-widget-ajax-images__button-delete"
            )
            .click();
        },
        success: function (data) {
          if (!data.file) {
            item
              .find(
                ".est-widget-ajax-files__button-delete, .est-widget-ajax-images__button-delete"
              )
              .click();
            return;
          }

          const input = $('<input type="hidden" />')
            .attr("name", `${fname}[${data.file.savename}]`)
            .val(field.is(".est-widget-ajax-files") ? data.file.name : "");

          wrap.empty().attr("data-message", "").append(input);

          input.trigger("change");

          wrap.css({
            backgroundSize:
              field.attr("data-preview-mode") === "resize"
                ? "contain"
                : "cover",
            backgroundImage: `url("${data.file.location}")`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50% 50%",
          });

          item.attr("data-ext", data.file.ext);
          item.attr("data-state", "filled");

          if (field.is(".est-widget-ajax-files")) {
            item.find(".button-rename").text(data.file.name);
          }

          field.trigger("queuee");
        },
      };

      const extradata = {
        resize_mode: field.attr("data-resize-mode"),
        resize_width: field.attr("data-resize-width"),
        resize_height: field.attr("data-resize-height"),
        preview_mode:
          field.attr("data-preview-mode") ||
          (field.is(".est-widget-ajax-images") ? "crop" : null),
        preview_width:
          field.attr("data-preview-width") ||
          (field.is(".est-widget-ajax-images") ? wrap.outerWidth() : null),
        preview_height:
          field.attr("data-preview-height") ||
          (field.is(".est-widget-ajax-images") ? wrap.outerHeight() : null),
      };

      uploadTempFile(file, extradata, ajaxopts);
    });

    field.trigger("change");
  }
);

$(document).on(
  "click",
  ".est-widget-ajax-files__button-delete, .est-widget-ajax-images__button-delete",
  function () {
    const field = $(this).closest(
      ".est-widget-ajax-files, .est-widget-ajax-images"
    );
    const fname = field.attr("data-name");
    const accept = field.attr("data-accept") || "*/*";
    const item = $(this).closest(".file-item");
    const wrap = item.find(
      ".est-widget-ajax-files__file-wrap, .est-widget-ajax-images__file-wrap"
    );

    item.attr("data-ext", "");
    item.attr("data-state", "empty");
    item.find(".button-rename").text(field.attr("data-rename-text") || "");
    wrap.data("file", null).attr("data-message", "").removeAttr("style");
    wrap
      .empty()
      .append(
        `<input type="file" name="${fname}[]" accept="${accept}"${
          isMultiuploadSupported ? ' multiple="multiple"' : ""
        } />`
      );

    field.append(item);
    field.trigger("queuee");
  }
);

$(document).on("click", ".est-widget-ajax-images__button-rotate", function () {
  const field = $(this).closest(".est-widget-ajax-images");
  const item = $(this).closest(".file-item");
  const wrap = item.find(".est-widget-ajax-images__file-wrap");
  const filename = wrap
    .find("input")
    .attr("name")
    .replace(/^.*\[([^\]]+)\]$/, "$1");
  const basename = filename.replace(/\.([^.]+)$/, "");
  const extension = filename.replace(/.*\.([^.]+)$/, "$1");
  let angle_old = parseInt(item.attr("data-angle")) || 0;
  let angle_new = angle_old + 90;

  while (angle_old >= 360) {
    angle_old -= 360;
  }
  while (angle_old < 0) {
    angle_old += 360;
  }
  while (angle_new >= 360) {
    angle_new -= 360;
  }
  while (angle_new < 0) {
    angle_new += 360;
  }

  if (item.attr("data-state") === "filled") {
    item.attr("data-angle", angle_new);
    item.attr("data-state", "rotate");

    $.ajax({
      dataType: "json",
      url: "/__ajax/rotate_temp_file.php",
      data: {
        filename: filename,
        rotate_angle: angle_new,
        resize_mode: field.attr("data-resize-mode"),
        resize_width: field.attr("data-resize-width"),
        resize_height: field.attr("data-resize-height"),
        preview_mode: "crop",
        preview_width: wrap.outerWidth(),
        preview_height: wrap.outerHeight(),
      },
      error: function () {
        item.attr("data-angle", angle_old);
        item.attr("data-state", "filled");
      },
      success: function (data) {
        if (data) {
          const img = new Image();
          img.onerror = () => {
            item.attr("data-state", "filled");
          };
          img.onload = () => {
            wrap.css("background-image", "url(" + img.src + ")");
            item.attr("data-state", "filled");
          };
          img.src = `/web/uploads/temp-file/${basename}-preview.${extension}?angle=${angle_new}`;
        } else {
          item.attr("data-angle", angle_old);
          item.attr("data-state", "filled");
        }
      },
    });
  }
});

$(document).on(
  "click",
  ".est-widget-ajax-files .file-item .button-rename, .est-widget-ajax-images .file-item .button-rename",
  function () {
    const field = $(this).closest(
      ".est-widget-ajax-files, .est-widget-ajax-images"
    );

    if (field.is('[data-rename-form="1"]')) {
      const item = $(this).closest(".file-item");
      const wrap = item.find(
        ".est-widget-ajax-files__file-wrap, .est-widget-ajax-images__file-wrap"
      );
      const pos = {
        my: "center top+5",
        at: "center bottom",
        of: wrap,
      };

      const input_src = item.find(':input[type="hidden"], textarea[hidden]');
      const input_tmp = $(
        input_src.is("textarea")
          ? '<textarea rows="1" class="est-textarea-autoheight" />'
          : '<input type="text" />'
      );

      in_rename = item;

      rename_form.appendTo(document.body);
      rename_form.width(wrap.outerWidth());
      rename_form.position(pos);
      rename_form
        .empty()
        .append(input_tmp)
        .append('<span class="btn-cancel">Отменить</span>')
        .append('<span class="btn-accept">Сохранить</span>');

      input_tmp.val(input_src.val()).select().focus().trigger("input");

      return false;
    }
  }
);
