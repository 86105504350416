"use strict";

import modules from "ym";
import "./jquery";
import "./loader_type_all";

const versions = require("./versions.json");
// const basepath = `https://cdnjs.cloudflare.com/ajax/libs/select2/${versions.select2}`;
const basepath = `https://cdn.jsdelivr.net/npm/select2@${versions.select2}/dist`;

modules.define("select2-base", ["loader_type_all", "jquery"], function (
  provide,
  loader,
  $
) {
  const is_prod = process.env.NODE_ENV === "production";

  if (typeof $.fn.select2 !== "undefined") {
    provide($.fn.select2);
  } else {
    loader({
      css: `${basepath}/css/select2${is_prod ? ".min" : ""}.css`,
      js: `${basepath}/js/select2${is_prod ? ".min" : ""}.js`,
      success: () => {
        if (typeof $.fn.select2 !== "undefined") {
          provide($.fn.select2);
        }
      },
    });
  }
});

modules.define("select2", ["loader_type_all", "select2-base"], function (
  provide,
  loader,
  select2
) {
  loader({
    js: basepath + "/js/i18n/ru.js",
    success: function () {
      provide(select2);
    },
    error: function () {
      provide(select2);
    },
  });
});
