import $ from "jquery";

$(document).on(
  "click",
  ".est-currency-selector__item:not(est-currency-selector__item--selected):not([href])",
  function () {
    const element = $(this);
    const currency = ("" + element.attr("data-currency")).toUpperCase();

    $(
      `.est-currency-selector__item:not([data-currency="${currency}"])`
    ).removeClass("est-currency-selector__item--selected");

    $(`.est-currency-selector__item[data-currency="${currency}"]`).addClass(
      "est-currency-selector__item--selected"
    );

    $(document.body)
      .removeClass(
        "is-currency-uah is-currency-usd is-currency-eur is-currency-rub"
      )
      .addClass("is-currency-" + currency.toLowerCase());

    $.ajax({
      url: "/currency/remember/" + currency.toLowerCase() + "/",
      method: "post",
    });
  }
);
