import "./_endless-scroll.scss";

import $ from "jquery";

$(function () {
  let els = $(".endless-scroll"),
    more = $(".show-more"),
    win = $(window),
    offset = 400 * 10,
    loading = false,
    count = 0,
    maxCount = 3,
    total = els.attr("data-total"),
    items = els.attr("data-item");

  more.fadeOut();

  if (els.length && total > $(items).length) {
    initEndlessScroll();
  }

  function initEndlessScroll() {
    win.on("scroll", function () {
      let el = $(".endless-scroll");
      if (el.length) {
        loading = el.hasClass("loading");
        if (!loading && count < maxCount) {
          let scrollTop = el.height() - win.height() - offset;
          if (scrollTop < win.scrollTop()) {
            loadData(el);
          }
        }
        if (count == maxCount) {
          more.fadeIn();
        }
      }
    });

    more.on("click", function () {
      $(this).hide();
      count = 0;
      loadData(els);
    });
  }

  function loadData(el) {
    let url = el.attr("data-url"),
      p = el.attr("data-p") * 1 + 1,
      item = el.attr("data-item");

    el.attr("data-p", p);

    $.ajax({
      url: url,
      data: {
        "p": p,
        "endless-scroll": true,
      },
      dataType: "jsonp",
      beforeSend: function () {
        el.addClass("loading");
      },
      error: function (data) {
        console.error("error");
      },
      success: function (data) {
        $(item).last().after(data);

        el.trigger("est-lazy-load");
      },
      complete: function () {
        el.removeClass("loading");
        count++;

        setInterval(function () {
          if (total <= $(item).length) {
            $(".endless-scroll").removeClass("endless-scroll");
          }
        }, 500);
      },
    });
  }
});
