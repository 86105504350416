// ----------------------------------------------------------------------------
// Скроллинг только в overflow элементе (document не скроллится)

import $ from "jquery";
import "jquery-mousewheel";

var scroll_animate = true;
var scroll_speed = scroll_animate ? 20 : 10;
var selector = ".est-select__dropdown, .est-scrollable";

$(document).on("mousewheel", selector, function (event) {
  var element = this;
  var scrollable = $(this);

  if (scroll_animate) {
    scrollable.stop();
    scrollable.animate(
      {
        scrollTop: element.scrollTop - scroll_speed * event.deltaY,
      },
      10,
      function () {
        scrollable.trigger("est-mouse-scroll");
      }
    );
  } else {
    element.scrollTop = element.scrollTop - scroll_speed * event.deltaY;
    scrollable.trigger("est-mouse-scroll");
  }

  return false;
});

$(document).on("est-mouse-scroll", selector, function () {
  var element = this;
  var scrollable = $(element);
  var is_at_top = element.scrollTop == 0;
  var is_at_bottom =
    element.scrollTop == element.scrollHeight - element.clientHeight;

  is_at_top
    ? scrollable.addClass("est-scrollable--top")
    : scrollable.removeClass("est-scrollable--top");

  is_at_bottom
    ? scrollable.addClass("est-scrollable--bottom")
    : scrollable.removeClass("est-scrollable--bottom");
});

$(function () {
  $(selector).trigger("est-mouse-scroll");
});
