import "./est-social-share.scss";

import $ from "jquery";
import qs from "qs";
import { language } from "../../lib/language";

const doc = document;
const lang_prefix = "/" + language;

const mklink = (params) => {
  let onclick;
  const link = doc.createElement("a");

  if (params.attributes && params.attributes.onclick) {
    onclick = params.onclick;
  } else if (params.share) {
    onclick = `window.open('${params.share}', '_blank', 'scrollbars=0, resizable=1, menubar=0, left=100, top=100, width=550, height=440, toolbar=0, status=0'); return false;`;
  } else {
    onclick = "";
  }

  link.setAttribute("rel", "nofollow");
  link.setAttribute("target", "_blank");
  link.setAttribute(
    "class",
    `est-social-share__item est-social-share__item--${params.name}`
  );
  link.setAttribute("title", params.title);
  link.setAttribute("href", params.href);

  onclick && link.setAttribute("onclick", onclick);

  Object.entries(params.attributes || {}).forEach((attr) => {
    if (attr[1]) {
      link.setAttribute(attr[0], attr[1]);
    }
  });

  return link;
};

function initEstSocialShare() {
  const isAuthenticated = $(doc.body).is(".body--auth-1");

  const g_url = "" + location.href;
  const g_title = "" + doc.title;
  const g_description =
    [...doc.querySelectorAll("meta[name]")].filter(
      (el) => el.name.toLowerCase() === "description"
    )[0] || "";

  [...doc.querySelectorAll(".est-social-share")]
    .filter((el) => el.className.indexOf("est-social-share--ready") === -1)
    .forEach((el) => {
      let u = el.getAttribute("data-url") || g_url;
      let t = el.getAttribute("data-title") || g_title || "";
      let d = el.getAttribute("data-description") || g_description || "";
      let i = el.getAttribute("data-image") || "";
      let s = (
        el.getAttribute("data-services") || "facebook,gplus,twitter,livejournal"
      ).split(",");

      const services = [
        {
          name: "facebook",
          href: "https://fb.com/",
          share:
            "//www.facebook.com/sharer/sharer.php?" + qs.stringify({ u: u }),
          title: "Поделиться в Facebook",
        },
        {
          name: "gplus",
          href: "https://plus.google.com/",
          share: "//plus.google.com/share?" + qs.stringify({ url: u }),
          title: "Поделиться в Google+",
        },
        {
          name: "twitter",
          href: "https://twitter.com/",
          share:
            "//twitter.com/intent/tweet?" + qs.stringify({ text: t, url: u }),
          title: "Добавить в Twitter",
        },
        {
          name: "livejournal",
          href:
            "//www.livejournal.com/update.bml?" +
            qs.stringify({ event: u, subject: t }),
          share: null,
          title: "Опубликовать в LiveJournal",
        },
        {
          name: "vkontakte",
          href: "https://vk.com/",
          share:
            "//vk.com/share.php?" +
            qs.stringify({ url: u, title: t, image: i, description: d }),
          title: "Поделиться В Контакте",
        },
      ];

      isAuthenticated &&
        services.unshift({
          name: "est",
          href: "https://est.ua/",
          title: "Поделиться в Est!",
          attributes: {
            "data-url":
              "//" +
              sf_host +
              lang_prefix +
              "/repost/?" +
              qs.stringify({ url: u }),
            "data-target": ":dialog",
            "onclick": false,
          },
        });

      const fragment = doc.createDocumentFragment();

      services
        .filter((params) => s.includes(params.name))
        .forEach((params) => fragment.appendChild(mklink(params)));

      el.innerHTML = "";
      el.appendChild(fragment);
    });
}

(() => {
  initEstSocialShare();
})();

$(".endless-scroll").on("est-lazy-load", () => {
  initEstSocialShare();
});
