import $ from "jquery";

$(document).on("click", "[data-ping-click]", function () {
  let i;
  let attr;
  let href;
  for (i = 0; i < 10; i++) {
    attr = i ? "data-ping-click-" + i : "data-ping-click";
    href = this.getAttribute(attr);
    href &&
      $.ajax(href, {
        crossDomain: true,
        method: "post",
        cache: false,
        xhrFields: {
          withCredentials: true,
        },
      });
  }
});
