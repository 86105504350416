import "../_ui-buttonset.scss";
import "./est-dialog.scss";

import $ from "jquery";
import "jquery-ui/ui/widgets/dialog.js";
import throttle from "lodash/throttle";

const $win = $(window);
const $doc = $(document);

$.widget("est.estdialog", $.ui.dialog, {
  options: {
    autoOpen: true,
    closeOnEscape: true,
    destroyOnClose: false,
    closeText: "Закрыть",
    dialogClass: "",
    hide: null,
    show: null,
    stack: true,
    title: "",
    modal: true,
    draggable: false,
    resizable: false,
    width: null,
    height: "auto",
    minHeight: false,
    minWidth: 150,
    maxHeight: false,
    maxWidth: false,
    // zIndex: 1005,
    buttons: {},
    position: {
      my: "center",
      at: "center",
      of: window,
      collision: "fit",
      // ensure that the titlebar is never outside the document
      using: function (pos) {
        var topOffset = $(this).css(pos).offset().top;
        if (topOffset < 0) {
          $(this).css("top", pos.top - topOffset);
        }
      },
    },
  },

  _create: function () {
    this.options.title =
      this.options.title ||
      this.element.attr("data-dialog-title") ||
      this.element.attr("title");
    this.options.width =
      this.options.width || this.element.attr("data-dialog-width") || 300;

    this._superApply(arguments);

    this.uiDialog.addClass("est-dialog");
    this.uiDialog.removeClass("ui-corner-all ui-widget ui-widget-content");
    this.uiDialogTitlebar.removeClass(
      "ui-corner-all ui-helper-clearfix ui-widget-header"
    );
    this.uiDialogTitlebarClose = this.uiDialog.find(
      ".ui-dialog-titlebar-close"
    );
    this.uiDialogTitlebarClose.removeClass(
      "ui-corner-all ui-button ui-widget ui-button-icon-only"
    );
    this.uiDialogTitlebarClose.empty().prependTo(this.uiDialog);
    this.uiDialogButtonPane.removeClass("ui-widget-content ui-helper-clearfix");
    this.uiButtonSet.removeClass("ui-dialog-buttonset");
    this.uiButtonSet.addClass("ui-buttonset");
    this.element.removeClass("ui-widget-content");

    this.options.title || this.uiDialog.addClass("est-dialog-notitle");
    this.options.modal === true && this.uiDialog.addClass("ui-dialog-modal");
    this.options.modal === true || this.uiDialog.removeClass("ui-dialog-modal");
  },

  _title: function (title) {
    if (this.options.title) {
      title.html(this.options.title);
    } else {
      title.html("&#160;");
    }
  },

  _createButtons: function () {
    var that = this;
    var buttons = this.options.buttons;

    // If we already have a button pane, remove it
    this.uiDialogButtonPane.remove();
    this.uiButtonSet.empty();

    if ($.isEmptyObject(buttons) || ($.isArray(buttons) && !buttons.length)) {
      this._removeClass(this.uiDialog, "ui-dialog-buttons");
      return;
    }

    $.each(buttons, function (name, props) {
      props = !$.isFunction(props)
        ? props
        : {
            click: props,
            text: name,
          };

      if (props.html) {
        delete props.text;
      } else {
        props = $.extend({ class: "ui-button" }, props);
      }

      var item;
      var button;
      var href = props.href || null;
      var click = props.click || null;
      var tag = href ? "<a />" : "<span />";

      delete props.click;
      delete props.icon;
      delete props.iconPosition;
      delete props.showLabel;

      button = $(tag, props);

      if ($.isFunction(click)) {
        button.on("click", function () {
          click.apply(that.element[0], arguments);
        });
      }

      item = $('<span class="ui-buttonset__item"></span>');
      item.append(button);
      item.prependTo(that.uiButtonSet);
    });

    this._addClass(this.uiDialog, "ui-dialog-buttons");
    this.uiDialogButtonPane.appendTo(this.uiDialog);
  },

  _focusTabbable: function () {
    // Set focus to the first match:
    // 1. An element that was focused previously
    // 2. First element inside the dialog matching [autofocus]
    // 3. Tabbable element inside the content element
    // 4. Tabbable element inside the buttonpane
    // 5. The close button
    // 6. The dialog itself
    var hasFocus = this._focusedElement;
    if (!hasFocus) {
      hasFocus = this.element.find("[autofocus]");
    }
    if (!hasFocus.length) {
      // hasFocus = this.element.find(":tabbable");
    }
    if (!hasFocus.length) {
      // hasFocus = this.uiDialogButtonPane.find(":tabbable");
    }
    if (!hasFocus.length) {
      // hasFocus = this.uiDialogTitlebarClose.filter(":tabbable");
    }
    if (!hasFocus.length) {
      hasFocus = this.uiDialog;
    }
    hasFocus.eq(0).trigger("focus");
  },

  _allowInteraction: function (event) {
    // @see https://github.com/select2/select2/issues/1246
    // @see http://stackoverflow.com/questions/16966002/select2-plugin-works-fine-when-not-inside-a-jquery-modal-dialog
    return !!$(event.target).closest(
      ".ui-dialog, .ui-datepicker, .select2-dropdown"
    ).length;
  },

  close: function (event) {
    this._superApply(arguments);

    $(document.documentElement).removeClass("est-unload");

    if (this.options.destroyOnClose === true) {
      this.destroy();
      this.element.remove();
    }
  },
});

const queueDialogsReposition = throttle(
  () => {
    $(".ui-dialog-content").each(function () {
      const dialog = $(this);
      if (dialog.is(":data(est-estdialog)") && dialog.estdialog("isOpen")) {
        const position = dialog.estdialog("option", "position");
        dialog.estdialog("option", "position", position);
      }
    });
  },
  50,
  {
    leading: true,
    trailing: true,
  }
);

$win.on("resize", queueDialogsReposition);
$win.on("scroll", queueDialogsReposition);

$doc.on("estdialogopen", function () {
  $(document.documentElement).addClass("html--with-dialog");
  $(".est-dropdown-toggle").removeClass("est-dropdown-toggle-active");
});

$doc.on("estdialogclose", function () {
  $(document.documentElement).removeClass("html--with-dialog");
});

$doc.on("click", ".command-close-dialog", function () {
  const dialog = $(this).closest(".ui-dialog").find(".ui-dialog-content");

  if (dialog.length && dialog.data("est-estdialog")) {
    dialog.estdialog("close");
  }
});

$doc.on("command-close-dialogs", function () {
  $(".ui-dialog-content").each(function () {
    if ($(this).data("est-estdialog")) {
      $(this).estdialog("close");
    }
  });
});
