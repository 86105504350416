// ----------------------------------------------------------------------------
// крестик очистки инпута

import $ from "jquery";

var is_touch_zone = true;
var btn_width = 13;
var btn_height = 13;
var btn_margin = 8;

function tog(v) {
  return v ? "addClass" : "removeClass";
}

$(document).on(
  "est-input-clearable-init focus drop paste keydown keypress input change",
  ".est-input-clearable",
  function () {
    $(this)[tog(this.value)]("est-input-clearable__x");
  }
);

$(document).on(
  "mousemove mouseenter mouseleave",
  ".est-input-clearable__x",
  function (e) {
    var input = $(this);
    var input_width = this.offsetWidth;
    var input_height = this.offsetHeight;
    var input_border_bottom = parseFloat(input.css("borderBottomWidth"));
    var input_border_right = parseFloat(input.css("borderRightWidth"));
    var input_border_left = parseFloat(input.css("borderLeftWidth"));
    var input_border_top = parseFloat(input.css("borderTopWidth"));
    var input_border_hr = input_border_left + input_border_right;
    var input_border_vr = input_border_top + input_border_bottom;
    var client_rect = this.getBoundingClientRect();
    var input_cursor_pos_x = e.clientX - client_rect.left;
    var input_cursor_pos_y = e.clientY - client_rect.top;
    var is_over_cross = true;

    if (is_touch_zone) {
      is_over_cross =
        is_over_cross &&
        input_cursor_pos_x >
          input_width - input_border_right - btn_width - btn_margin * 2;
      is_over_cross =
        is_over_cross && input_cursor_pos_x < input_width - input_border_right;
      is_over_cross = is_over_cross && input_cursor_pos_y > input_border_top;
      is_over_cross =
        is_over_cross &&
        input_cursor_pos_y < input_height - input_border_bottom;
    } else {
      is_over_cross =
        is_over_cross &&
        input_cursor_pos_x >=
          input_width - input_border_hr - btn_margin - btn_width;
      is_over_cross =
        is_over_cross &&
        input_cursor_pos_x <= input_width - input_border_hr - btn_margin;
      is_over_cross =
        is_over_cross &&
        input_cursor_pos_y >= (input_height - input_border_vr - btn_height) / 2;
      is_over_cross =
        is_over_cross &&
        input_cursor_pos_y <=
          (input_height - input_border_vr - btn_height) / 2 + btn_height;
    }

    $(this)[tog(is_over_cross)]("est-input-clearable__x-over");
  }
);

$(document).on("click", ".est-input-clearable__x-over", function () {
  var input = $(this);
  var form = input.is(".est-input-clearable--autosubmit")
    ? $(this).closest("form")
    : null;
  input
    .removeClass("est-input-clearable__x est-input-clearable__x-over")
    .trigger("input")
    .val("")
    .trigger("change")
    .trigger("est-input-clearable-clear");
  form && form.length && form.submit();
});

$(function () {
  $(".est-input-clearable").trigger("est-input-clearable-init");
});
