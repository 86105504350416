// ----------------------------------------------------------------------------
// ссылка закрытия окна

import $ from "jquery";

if (window.opener) {
  $(function () {
    $(document.body).addClass("is-popup");
  });
}

$(document).on("click", ".command-close-window", function () {
  window.close();
});
