import "./comment-block.scss";

import $ from "jquery";
import metas from "../../lib/metas";

let timeout;

const block = $(".comment-block");
const reload_time =
  1000 * parseInt(metas.getFirst("comment-block-reload")) || 0;

function is_interact() {
  let is = false;

  if (!is) {
    is = $(".ui-dialog:visible").length > 0;
  }

  if (!is) {
    block.find("textarea").each(function () {
      if (!is) {
        const area = $(this);
        if (area.is(":focus") || area.val()) {
          is = true;
        }
      }
    });
  }

  return is;
}

function enqueue() {
  clearTimeout(timeout);
  timeout = setTimeout(tick, reload_time);
}

function tick() {
  if (is_interact()) {
    enqueue();
  } else {
    $.ajax({
      url: location.href,
      cache: false,
      complete: function () {
        enqueue();
      },
      success: function (data) {
        if (!is_interact()) {
          block.html(
            $("<div />").append($.parseHTML(data)).find(".comment-block > *")
          );
        }
      },
    });
  }
}

if (reload_time) {
  block.length && enqueue();
}
