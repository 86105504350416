// ----------------------------------------------------------------------------
// Ссылка с POST методом перехода

import $ from "jquery";

$(document).on(
  "click",
  'a[data-method="post"][href], a[data-method="post"][data-href]',
  function (event) {
    const link = $(this);
    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute(
      "action",
      link.attr("data-href") || link.attr("data-url") || link.attr("href")
    );
    document.body.appendChild(form);
    form.submit();

    event.preventDefault();
  }
);
