// ----------------------------------------------------------------------------
// Оценка рейтинга сайта

import "./_rating-site.scss";

import $ from "jquery";

const stars_wrapper = $(".rating-site__stars-wrapper");
if (stars_wrapper.length) {
  stars_wrapper.on("mousemove", function (e) {
    const max_width = $(this).width();
    const elm_pos = $(this).offset();
    const cur_pos = {
      left: e.pageX,
      top: e.pageY,
    };
    const loc_pos = {
      left: cur_pos.left - elm_pos.left,
      top: cur_pos.top - elm_pos.top,
    };
    const my_vote = Math.max(
      1,
      Math.min(5, 1 + parseInt((5 * loc_pos.left) / max_width))
    );

    $(".rating-site__stars-maybe").css(
      "width",
      (my_vote * max_width) / 5 + "px"
    );
  });

  stars_wrapper.on("click", function (e) {
    const max_width = $(this).width();
    const elm_pos = $(this).offset();
    const cur_pos = {
      left: e.pageX,
      top: e.pageY,
    };
    const loc_pos = {
      left: cur_pos.left - elm_pos.left,
      top: cur_pos.top - elm_pos.top,
    };
    const my_vote = Math.max(
      1,
      Math.min(5, 1 + parseInt((5 * loc_pos.left) / max_width))
    );

    $.ajax({
      type: "POST",
      data: {
        vote: my_vote,
      },
      url: "/rating-site/",
      success: function (obj) {
        if (obj.success == 1) {
          $(".rating-site__stars-current").css(
            "width",
            parseInt(obj.percent) + "%"
          );
          $(".rating-site__average-value").text(obj.average);
          $(".rating-site__votes-value").text(obj.votes);
          $(".rating-site__message").html(obj.message || "");
        }
      },
    });
  });
}
