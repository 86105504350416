import modules from "ym";
import "../modules/storage";

const dummyFunction = () => {};

const run = (callback) => {
  modules.require(["storage"], (storage) => {
    callback(storage);
  });
};

export default {
  local: {
    async setItem(key, val, callback = dummyFunction) {
      return new Promise((resolve) => {
        run((storage) => {
          storage.local.setItem(key, val, (data) => {
            callback(data);
            resolve(data);
          });
        });
      });
    },
    async getItem(key, callback = dummyFunction) {
      return new Promise((resolve) => {
        run((storage) => {
          storage.local.getItem(key, (data) => {
            callback(data);
            resolve(data);
          });
        });
      });
    },
    async removeItem(key, callback = dummyFunction) {
      return new Promise((resolve) => {
        run((storage) => {
          storage.local.removeItem(key, (data) => {
            callback(data);
            resolve(data);
          });
        });
      });
    },
  },
  session: {
    async setItem(key, val, callback = dummyFunction) {
      return new Promise((resolve) => {
        run((storage) => {
          storage.session.setItem(key, val, (data) => {
            callback(data);
            resolve(data);
          });
        });
      });
    },
    async getItem(key, callback = dummyFunction) {
      return new Promise((resolve) => {
        run((storage) => {
          storage.session.getItem(key, (data) => {
            callback(data);
            resolve(data);
          });
        });
      });
    },
    async removeItem(key, callback = dummyFunction) {
      return new Promise((resolve) => {
        run((storage) => {
          storage.session.removeItem(key, (data) => {
            callback(data);
            resolve(data);
          });
        });
      });
    },
  },
};
