import modules from "ym";

const versions = require("./versions.json");
const basepath = `https://cdnjs.cloudflare.com/ajax/libs/codemirror/${versions.codemirror}`;

modules.define("codemirror", ["loader_type_all"], function (provide, loader) {
  loader({
    css: basepath + "/codemirror.min.css",
    js: basepath + "/codemirror.min.js",
    success: function () {
      if (typeof global.CodeMirror !== "undefined") {
        const CodeMirror = global.CodeMirror;

        loader({
          css: [
            basepath + "/addon/dialog/dialog.min.css",
            basepath + "/addon/display/fullscreen.css",
            basepath + "/addon/scroll/simplescrollbars.min.css",
            basepath + "/addon/search/matchesonscrollbar.css",
            basepath + "/addon/hint/show-hint.css",
          ],
          js: [
            basepath + "/addon/dialog/dialog.min.js",
            basepath + "/addon/display/fullscreen.min.js",
            basepath + "/addon/display/placeholder.min.js",
            basepath + "/addon/edit/closebrackets.min.js",
            basepath + "/addon/edit/closetag.min.js",
            basepath + "/addon/edit/matchbrackets.min.js",
            basepath + "/addon/edit/matchtags.min.js",
            basepath + "/addon/edit/trailingspace.min.js",
            basepath + "/addon/mode/multiplex.min.js",
            basepath + "/addon/scroll/annotatescrollbar.min.js",
            basepath + "/addon/scroll/simplescrollbars.min.js",
            basepath + "/addon/search/matchesonscrollbar.min.js",
            basepath + "/addon/search/search.min.js",
            basepath + "/addon/search/searchcursor.min.js",
            basepath + "/addon/selection/active-line.min.js",
            basepath + "/addon/hint/show-hint.js",
            basepath + "/addon/hint/javascript-hint.js",
            basepath + "/addon/hint/xml-hint.js",
            basepath + "/addon/hint/html-hint.js",
            basepath + "/addon/hint/css-hint.js",
            basepath + "/addon/hint/sql-hint.js",
            basepath + "/mode/css/css.min.js",
            basepath + "/mode/javascript/javascript.min.js",
            basepath + "/mode/markdown/markdown.min.js",
            basepath + "/mode/php/php.min.js",
            basepath + "/mode/sass/sass.min.js",
            basepath + "/mode/sql/sql.min.js",
            basepath + "/mode/xml/xml.min.js",
            basepath + "/mode/yaml/yaml.min.js",
          ],
          success: function () {
            loader({
              js: [
                basepath + "/mode/htmlmixed/htmlmixed.min.js",
                basepath + "/mode/twig/twig.min.js",
              ],
              success: function () {
                CodeMirror.defineMode("twigmixed", function (config) {
                  return CodeMirror.multiplexingMode(
                    CodeMirror.getMode(config, "htmlmixed"),
                    {
                      open: /\{%/,
                      close: /%\}/,
                      mode: CodeMirror.getMode(config, "twig"),
                      parseDelimiters: true,
                    },
                    {
                      open: /\{#/,
                      close: /#\}/,
                      mode: CodeMirror.getMode(config, "twig"),
                      parseDelimiters: true,
                    },
                    {
                      open: /\{\{/,
                      close: /\}\}/,
                      mode: CodeMirror.getMode(config, "twig"),
                      parseDelimiters: true,
                    }
                  );
                });

                provide(CodeMirror);
              },
            });
          },
        });
      }
    },
  });
});
