/**
 * Дополнительные методы валидатора
 */
import $ from "jquery";

const assign = Object.assign;

if ($.validator && $.validator.addMethod) {
  assign($.validator.prototype, {
    resetRules: function (rules) {
      this.settings.rules = rules || {};
      this.submitted = {};
      this.lastElement = null;
      this.prepareForm();
      this.hideErrors();

      const jForm = $(this.currentForm);
      jForm
        .find("." + this.settings.errorClass)
        .removeClass(this.settings.errorClass);
      jForm.find(".fc-field-error").empty();

      return this;
    },
    resetMessages: function (messages) {
      this.settings.messages = messages || {};
      return this;
    },
  });

  // https://github.com/jzaefferer/jquery-validation/issues/301
  // https://github.com/jzaefferer/jquery-validation/pull/344
  // https://github.com/jzaefferer/jquery-validation/commit/e036239746aabc35bb00684d4f15da19c5710486
  assign($.validator, {
    attributeRules: function (element) {
      const $element = $(element);
      let rules = {};
      let method;

      for (method in $.validator.methods) {
        if ($.validator.methods.hasOwnProperty(method)) {
          let value;
          // support for <input required> in both html5 and older browsers
          if (method === "required") {
            value = $element.get(0).getAttribute(method);
            // Some browsers return an empty string for the required attribute
            // and non-HTML5 browsers might have required="" markup
            if (value === "") {
              value = true;
            } else if (value === "false") {
              value = false;
            }
            // force non-HTML5 browsers to return bool
            value = !!value;
          } else {
            value = $element.attr(method);
          }
          if (value) {
            rules[method] = value;
          } else if ($element[0].getAttribute("type") === method) {
            rules[method] = true;
          }
        }
      }

      // maxlength may be returned as -1, 2147483647 (IE) and 524288 (safari) for text inputs
      if (rules.maxlength && /-1|2147483647|524288/.test(rules.maxlength)) {
        delete rules.maxlength;
      }

      return rules;
    },
  });

  $.validator.addMethod("callback", function (value, element, param) {
    return param.callback.call(this, value, element);
  });

  $.validator.addMethod("callback1", function (value, element, param) {
    return param.callback.call(this, value, element);
  });

  $.validator.addMethod("callback2", function (value, element, param) {
    return param.callback.call(this, value, element);
  });

  $.validator.addMethod("callback3", function (value, element, param) {
    return param.callback.call(this, value, element);
  });

  $.validator.addMethod("required", function (value, element, param) {
    const jElement = $(element);
    let sDefault;
    let jFcField;

    // check if dependency is met
    if (!this.depend(param, element)) {
      return "dependency-mismatch";
    }

    if (element.nodeName.toLowerCase() == "select") {
      const val = $(element).val();
      if (jElement.is(".fc-field select")) {
        jFcField = jElement.closest(".fc-field");
        sDefault = jFcField.attr("data-default");
        return val && val.length > 0 && val != sDefault;
      } else {
        return val && val.length > 0;
      }
    }

    if (this.checkable(element)) {
      const checked = this.findByName(element.name).filter(":checked");

      if (jElement.is(".fc-field input")) {
        jFcField = jElement.closest(".fc-field");
        sDefault = jFcField.attr("data-default");
        return (
          checked.length > 0 &&
          value != sDefault &&
          checked.filter('[data-is-empty="1"]').length == 0 &&
          checked.filter('[value=""]').length == 0 &&
          checked.filter(":not([value])").length == 0
        );
      } else {
        return (
          checked.length > 0 &&
          checked.filter('[value=""]').length == 0 &&
          checked.filter(":not([value])").length == 0
        );
      }
    }

    return $.trim(value).length > 0;
  });

  $.validator.addMethod(
    "pattern",
    function (value, element, param) {
      const pattern = param.test ? param : new RegExp(param);
      return this.optional(element) || pattern.test(value);
    },
    "Пожалуйста, введите правильное значение."
  );

  $.validator.addMethod(
    "esturl",
    function (value, element) {
      const pattern1 = /^(?:(?:(?:(?:http|https|ftp):)?\/\/)(?:\S+(?::\S*)?@)?|(?:(?:(?:http|https|ftp):)?\/\/)?)(?:localhost|(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])(?:\.(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])){3}|\[(?:(?:[0-9a-fA-F:]){1,4}(?:(?::(?:[0-9a-fA-F]){1,4}|:)){2,7})+\]|(?:(?:[a-z\u00A1-\uFFFF0-9]-*)*[a-z\u00A1-\uFFFF0-9]+)(?:\.(?:[a-z\u00A1-\uFFFF0-9]-*)*[a-z\u00A1-\uFFFF0-9]+)*(?:\.(?:[a-z]{2,}|xn--[a-z0-9]+|рф|укр|бел)))(?::\d{2,5})?(?:[\/][^\s"#?]*)?(?:[?][^\s#"]*)?(?:[#][^\s"]*)?$/i;
      const pattern2 = /^((https?|ftp):\/\/)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
      return this.optional(element) || pattern1.test(value);
    },
    "Пожалуйста, введите корректный URL."
  );

  $.validator.addMethod(
    "captcha",
    function (value, element, param) {
      const validator = this;

      if (value.length < 2) {
        return "pending";
      }

      if (this.optional(element)) {
        return "dependency-mismatch";
      }

      const previous = this.previousValue(element);

      if (!this.settings.messages[element.name]) {
        this.settings.messages[element.name] = {};
      }
      previous.originalMessage = this.settings.messages[element.name].captcha;
      this.settings.messages[element.name].captcha = previous.message;

      param =
        (typeof param == "string" && {
          url: param,
        }) ||
        param;

      if (this.pending[element.name]) {
        return "pending";
      }
      if (previous.old === value) {
        return previous.valid;
      }

      previous.old = value;

      this.startRequest(element);

      $.ajax(
        $.extend(
          true,
          {
            url: param,
            mode: "abort",
            port: "validate" + element.name,
            dataType: "json",
            data: {
              captcha: value,
            },
            success: function (response) {
              validator.settings.messages[element.name].captcha =
                previous.originalMessage;
              const valid = response === true;
              if (valid) {
                const submitted = validator.formSubmitted;
                validator.prepareElement(element);
                validator.formSubmitted = submitted;
                validator.successList.push(element);
                validator.showErrors();
              } else {
                let errors = {};
                const message =
                  response || validator.defaultMessage(element, "captcha");
                errors[element.name] = previous.message = $.isFunction(message)
                  ? message(value)
                  : message;
                validator.showErrors(errors);
              }
              previous.valid = valid;
              validator.stopRequest(element, valid);
            },
          },
          param
        )
      );
      return "pending";
    },
    "Пожалуйста, введите правильное значение."
  );

  assign($.validator.methods, {
    number: function (value, element) {
      return this.optional(element) || /^-?(?:\d+)(?:[.,]\d+)?$/.test(value);
    },
    min: function (value, element, param) {
      return (
        this.optional(element) ||
        Number(("" + value).replace(",", ".")) >= param
      );
    },
    max: function (value, element, param) {
      return (
        this.optional(element) ||
        Number(("" + value).replace(",", ".")) <= param
      );
    },
  });
}
