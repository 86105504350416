export default function (name) {
  let ext = name.replace(/^.*\.([^.]+)$/, "$1").toLowerCase();
  let exts = {
    jpeg: "image/jpeg",
    jpg: "image/jpeg",
    png: "image/png",
    gif: "image/gif",
  };
  return exts[ext] || "application/octet-stream";
}
