"use strict";

const doc = document;

/**
 * works only on text|search|password|tel|url inputs and textareas
 * e.g. doesn't work on number input
 */
export default function (el) {
  let start = 0;
  let end = 0;
  let normalizedValue;
  let range;
  let textInputRange;
  let len;
  let endRange;
  let statusSelection = false;

  if ("selectionStart" in el && "selectionEnd" in el) {
    start = el.selectionStart;
    end = el.selectionEnd;
  } else if ("value" in el) {
    start = el.value.length;
    end = el.value.length;
  }

  if (doc.selection) {
    range = doc.selection.createRange();

    if (range && range.parentElement() === el) {
      len = el.value.length;
      normalizedValue = el.value.replace(/\r\n/g, "\n");

      // Create a working TextRange that lives only in the input
      textInputRange = el.createTextRange();
      textInputRange.moveToBookmark(range.getBookmark());

      // Check if the start and end of the selection are at the very end
      // of the input, since moveStart/moveEnd doesn't return what we want
      // in those cases
      endRange = el.createTextRange();
      endRange.collapse(false);

      if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
        start = end = len;
      } else {
        start = -textInputRange.moveStart("character", -len);
        start += normalizedValue.slice(0, start).split("\n").length - 1;

        if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
          end = len;
        } else {
          end = -textInputRange.moveEnd("character", -len);
          end += normalizedValue.slice(0, end).split("\n").length - 1;
        }
      }
    }
  }

  if (start - end !== 0) {
    statusSelection = true;
  }

  return {
    statusSelection: statusSelection,
    start: start,
    end: end,
  };
}
