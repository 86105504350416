import $ from "jquery";
import modules from "ym";
import "../modules/recaptcha";
import listenToMediaQuery from "../lib/listen-to-media-query";

let is_desktop_screen = false;

listenToMediaQuery("screen and (min-width: 340px)", (matches) => {
  is_desktop_screen = matches;
});

function init_recaptcha(container) {
  [...container.querySelectorAll(".est-recaptcha[data-sitekey]")].forEach(
    function (element) {
      let sitekey, size, badge, theme;

      const callback = () => {
        $(element).trigger("captcha", true);
      };

      modules.require(["recaptcha"], function (grecaptcha) {
        sitekey = element.getAttribute("data-sitekey");
        size = element.getAttribute("data-size") || "normal";
        size = is_desktop_screen || size === "invisible" ? size : "compact";
        badge = size === "invisible" ? "inline" : "bottomright";
        theme = element.getAttribute("data-theme") || "light";
        badge = "bottomright";

        if (element.hasAttribute("data-recaptcha-id")) {
          let id = parseInt(element.getAttribute("data-recaptcha-id"));
          grecaptcha.reset(id);
        } else {
          let id = grecaptcha.render(element, {
            sitekey: sitekey,
            theme: theme,
            badge: size,
            size: size,
            callback: callback,
          });
          element.setAttribute("data-recaptcha-id", id);
        }
      });
    }
  );
}

$(window).on("load", function () {
  init_recaptcha(document);
});
$(document).on("est-init-recaptcha", function () {
  init_recaptcha(document);
});
$(document).on("estdialogopen", function (event) {
  init_recaptcha(event.target);
});
