import $ from "jquery";

$(function () {
  let els = $(".est-ajax-load:not(.loaded)");

  if (els.length) {
    els.each(function (index, el) {
      loadData($(el));
    });
  }

  function loadData(el) {
    let url = el.attr("data-url");

    if (url) {
      $.ajax({
        url: url,
        dataType: "jsonp",
        beforeSend: function () {},
        error: function (data) {
          console.log("error");
        },
        success: function (data) {
          $(el).html(data).addClass("loaded");
        },
        complete: function () {},
      });
    }
  }
});
