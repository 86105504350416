import hash from "../lib/hash";

let collection = {};

[...document.querySelectorAll("[data-click-hashbang]")].forEach((element) => {
  let key = element.getAttribute("data-click-hashbang");
  if (key) {
    collection[key] = element;
  }
});

const hash_change_handler = () => {
  for (let i in collection) {
    if (collection.hasOwnProperty(i)) {
      let key = i;
      let elm = collection[key];

      if (hash.hasItem(key)) {
        hash.removeItem(key);
        elm.click();
      }
    }
  }
};

window.addEventListener("hashchange", hash_change_handler, false);

hash_change_handler();
