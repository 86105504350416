import $ from "jquery";
import modules from "ym";
import "../components/est-dialog/est-dialog";

modules.define("ajax-dialog", function (provide) {
  provide(function (url, options = {}) {
    let url_space_pos = url.indexOf(" ");
    let url_selector = null;

    url_selector =
      url_space_pos >= 0 ? url.slice(url_space_pos, url.length) : null;
    url = url_space_pos >= 0 ? url.slice(0, url_space_pos) : url;

    let ajax_data = {
      overlay: options.overlay || false,
      url: url,
      data: {
        sf_format: "jsonp",
      },
      dataType: "jsonp",
      complete: function () {
        $.data(document, "ajax-send", false);
      },
      success: function (data) {
        let html = !url_selector
          ? data
          : $("<div />").append($.parseHTML(data)).find(url_selector);

        $(document).trigger("command-close-dialogs");

        let params;
        let dialog;
        let tmp = $("<div />").append(html);

        if (tmp.children().length == 1) {
          dialog = $(tmp.children()[0]);
          setTimeout(function () {
            tmp.remove();
          }, 5000);
        } else {
          dialog = tmp;
        }

        params = {
          title:
            options.title ||
            dialog.attr("data-dialog-title") ||
            dialog.attr("title") ||
            null,
          width: options.width || dialog.attr("data-dialog-width") || 300,
          height: options.height || dialog.attr("data-dialog-height") || null,
          destroyOnClose: true,
          resizable: false,
          draggable: true,
          autoOpen: true,
          modal: true,
        };

        if (!params.title) {
          delete params.title;
        }
        if (!params.width) {
          delete params.width;
        }
        if (!params.height) {
          delete params.height;
        }

        dialog.appendTo(document.body);
        dialog.estdialog(params);
      },
    };

    $.data(document, "ajax-send", true);
    $.ajax(ajax_data);
  });
});
